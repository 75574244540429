
import {
  Box, Button, Dialog,DialogContent, Grid,Typography,IconButton,Card,CardContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cookie from 'react-cookies';

import axios from 'axios';
import React, { Component , Suspense } from 'react';
import AlertBox from "../../../AlertBox";
import { config, api, getUserDetails,genPassword } from "../../../Helper"
import AlertDialog from '../../../AlertDialog';



const GoogleLog = React.lazy(() => import('./GoogleLogin'));
const FacebookLog = React.lazy(() => import('./FacebookLogin'));
const LinkedInLog = React.lazy(() => import('./Linked'));


//const random_list ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//clientId="670033760057-4tjhv7ua0a02v2ef660c3t9jcvlfg4u2.apps.googleusercontent.com"

let academy_info=[];
class Social extends Component {

		constructor(props) {
		super(props);
		this.getUserDetails = getUserDetails.bind(this)
		this.state = {
			
			email:null,
			password:null,
			webinar_id:null,
			ip_address:null,
			user_choice:'student',
			code:'',
			errorMessage:'',userIs:'guest',
			msg:false,msg_type:'success',
           loading:false,
           dialog_op:false,
           dialogopen:false,
           form_for:null,
		   
		   google:false,
		  facebook:false,
		   linkedin:false,
			
		}
	  }
      setMessages=(msg,msg_type,path)=>
      {
          this.setState({
               msg: msg,
               msg_type: msg_type
            })
          this.state.msg=msg;
          setTimeout(() => 
		  {
				this.setState({
                   msg: false
                })
                
		  }, 3000);
          
          if(path!='empty')
          {
            setTimeout(() => {window.location = path;}, 4000);
          }
          
      }    

      setLogin=(user,data)=>
      {
		  	 
		
		//alert(user)
		
        if(user=="guest")
		{
			if(this.props.requestFor=="register")
			{
				this.loader(data,true);
				if(this.props.user=='professor')
				{
					this.addUser(academy_info[0],academy_info[1],'professor')
				}	
				else
				{
					this.addUser(academy_info[0],academy_info[1],'student')
				}
			}
			else
			{
				this.setDialog(true)
			}

		}
		else
        {
            if(this.props.requestFor=="register")
			{
				 this.setMessages("Email already exists!","warning",'empty');
				 this.loader(academy_info[1],false);
			}
			else
			{
				 this.loader(academy_info[1],false);
				 data.user = user
				 let txt=user.toLowerCase()==('professor').toLowerCase()?'Mentor':'Learner'
				 this.setMessages("You are logging in as "+txt,"success",'empty')
				 this.update_social_details(data)
				 this.manage_social(data)

				 setTimeout(() => {
					 
				 localStorage.setItem('login_is',"Valid");
				 this.getUserDetails(data.email);
				// this.upload_social_img(data)
				 }, 3000);
			}
	
		}   
      }
      update_social_details=(data)=>
      {
            axios.post(api+'/update_social_details',data)
            .then((response) => {
                //console.log(response.data)
                //console.log(response.data['status'])
                });   
      } 
      
      upload_social_img=(data)=>
      {
            axios.post(api+'/upload_social_img',data)
            .then((response) => {console.log(response.data)});   
      } 
     
      manage_social=(data)=>
      {
            axios.post(api+'/manage_social',data)
            .then((response) => {console.log(response.data)
            if(response.data['status']=='success'){

                //console.log("success")
            }
            else
            {
                //console.log("error")
            }    
            });   
      }
      addUser=(user,data,user_choice)=>
      {
		this.setDialog(false)
		data['user']=user_choice
        if(user=='guest' || user=='webinar')
        {
            axios.post(api+'/add_with_social',data)
            .then((response) => {
                
                
           	this.loader(academy_info[1],false);
            if(response.data['user']==user_choice)
            {
                
                this.manage_social(data)
                 let txt=(response.data['user']).toLowerCase()==('professor').toLowerCase()?'Expert':'User'
                 this.setMessages("You are successfully registered as "+txt,"success",'empty')
                 
				 
				 if(this.props.requestFor=="register")
				 {
					 localStorage.setItem('login_is',"Valid");
					 this.getUserDetails(data.email);
				 } 
				 
                /* if(this.props.requestFor=="login")
                 {
                       this.setLogin(user_choice,academy_info[1])
                        this.setState({loading: true});
                 }
                 else
                 {
                      
					   this.upload_social_img(data)
                 } */
				this.setLogin(user_choice,academy_info[1])
                this.loader(academy_info[1],true);
			 
             }
            else
            {
                
                this.setMessages("Signup failed","warning",'')
            } 
            
            });
        }
        else
        {
			this.loader(academy_info[1],false);
           this.setMessages("User already exists","warning",'/') 
        }
      }
   
      loader=(data,values)=>{
		  
		 let val=data['login_type'];
		 //console.log(val);
		 
		 
		 this.setState({[val]:values},
		 ()=>{console.log(this.state[val])});
		 
		 this.forceUpdate();
		  
	  }
      verifyEmail=(data)=>{
		  
		//console.log(data);
		this.loader(data,true);
        axios.post(api+'/login_user', 
		{'email': data.email})
		.then((response) => {
		
			academy_info.push(response.data['user_is'])
			academy_info.push(data)
			
		
			
			if(response.data['platform']=="webinar" && 
			this.props.requestFor==undefined)
			{
				//this.setForm("webinar-login")
				if(config.webinar.value === 1)
				{
					this.webinar_login()
				}
				else
				{
					 this.setState({dialogopen:true},()=>{console.log(this.state.dialogopen)});		
					 this.loader(data,false);
				}
				
			}
			
			else 
			{
				this.setLogin(academy_info[0],academy_info[1])
				
			}
				
		});
	 }
     
		componentDidMount() {
			
            fetch('https://api.ipify.org/?format=json')
			.then(res => res.json())
			.then((data) => {
			  this.setState({ ip_address: data['ip'] })
			})
			.catch(console.log)
            
            //alert(this.props.user)
            //alert(this.props.requestFor)
			//this.getActiveWebinarId();
		}

	
	
	
	
  async getValidUserDe(param) {
        const res = await axios.post(api+'/login_webinar',param);
         return await res; 
    }
	moveToWebinar=()=>
	{
        
        if(this.state.webinar_id=='None')
        {
            window.location = '/webinars';

        } 
        else 
        {
                axios.post('/api/checkTimer')
                .then((response) => {				
				cookie.save('webinar_timer_de',response.data, { path: '/' })
                if(response.data['started']==true)
                {
                    if(response.data['exceded']==true)
					{
						window.location = '/og-webinars';
					}
					else
					{
						window.location = '/og-webinars/'+this.state.webinar_id;
					}
                }
                else
                {
                    window.location = '/og-webinars';
                }
            });
        }
	}

	webinar_login=()=>
    {
	    //alert("n")
		this.loader(academy_info[1],true);
 
		//this.setDialog(false)
		let res="valid"
          let param={ 
         'platform': 'webinar',
         'email': academy_info[1]['email'],
		 'login_with':"social"}
		 
		 //console.log(param)
		 
		 
         this.getValidUserDe(param).then((response) => {
         console.info(response.data);
    		this.loader(academy_info[1],false);
         
         if(response.data['status']=="invalid" )
         {
			 //alert(response.data['status'])
              localStorage.setItem('login_is',"Invalid");
		      
			 
				this.setState({msg:"Invalid user credential"})  
			  
			  setTimeout(() => {this.setState({msg:false})}, 2000);
			  res="invalid"
         }
		 else
		 {
            localStorage.setItem('login_is',"Valid");
			this.setState({msg:"You have successfully logged in."});
            localStorage.setItem('login_token','webinar')
			localStorage.setItem('ac_login_user', JSON.stringify(response.data)) 
            cookie.save('login_token','webinar', { path: '/' })
			res="valid"
            this.moveToWebinar();
           }  
		})
         .catch(() => { /*...handle the error...*/});
		 return res;
	}
  	getActiveWebinarId=()=>
	{
		axios.post('/api/getActiveWebinarId')
			.then((response) => {
            this.setState({'webinar_id':response.data})
            localStorage.setItem('webinar_id',response.data)
		});
	} 
 	setDialog=(val)=>
	{
       this.setState({'dialog_op':val},()=>{console.log(this.state.dialog_op)})
	}
	setForm=(val)=>
	{
       this.setState({'form_for':val},()=>{console.log(this.state.form_for)})
	}
	 createProfileRequest = () => {
		 
              let param=academy_info[1];


              let lname=param['lname']==undefined || param['lname']==null || param['lname']=="" ?null:param['lname'];
			  
			  
			
			 let payload={
				 'webinar_id':this.state.webinar_id,
				 'fname':param['fname'],
				 'lname':lname,
				 'email':param['email'],
				 'loc':'register',
                 ip:this.state.ip_address,
				 password:genPassword(),
				 img:param['img'],
				 img_name:param['img_name'],
				 }
			  
			axios.post('/api/createProfile',payload)
			.then(() => {
				this.setState({email:param['email']},()=>{console.log(this.state.email)})
				this.webinar_login()
			});	
    }	
	handleClose=(_, reason) => {
		
          if (reason !== "backdropClick") {
			this.setDialog(false);
			this.loader(academy_info[1],false);

			this.forceUpdate();
			
          }
		
			 
	}
	render() {	

		const WebCard= () => {
		return (	
		<Box 
		margin={this.props.requestFor==undefined?"16px 4px":"0px 0px 16px"}>
        <Grid container 
                  direction="row"
                  alignItems="center"
                  justify="center"
                  spacing={1}>
            
            
            <Grid item xs={12} sm={6}>
                <Suspense fallback={<div> </div>}>
                   <GoogleLog 
				   parentCallback={this.verifyEmail} 
				   loading={this.state.google} 
				   /> 
                </Suspense>
		</Grid>  
            
		<Grid item xs={12} sm={6}>
                <Suspense fallback={<div> </div>}>
                    <LinkedInLog 
					parentCallback={this.verifyEmail} 
					loading={this.state.linkedin} 
					/> 
                </Suspense>   
            </Grid>
            
            <Grid item xs={12} >
                
            <Box display= 'flex' justifyContent="center" alignItems="center" marginTop='8px'>
            <Typography  align="center"   variant="caption" color="textSecondary">OR</Typography> 
            </Box>
            
            </Grid>        
         </Grid> 		
         </Box> 		
		)}	
			const AcReg = () => {
	return (
	<Grid container spacing={2}>
		<Grid item xs={12} >
		<Typography variant="h6" color="textPrimary">
				 <Box fontWeight="600"  className="format-text">Get yourself Registered!</Box>
				</Typography>
	</Grid>
				  
	<Grid item xs={12} >
		<Box display="flex" flexDirection="row-reverse" >
						
			<Button variant="contained" size="large"  
			color="primary"  
			fullWidth
			onClick={()=>{this.addUser(academy_info[0],academy_info[1],'professor')}}>
			As Expert
			</Button>
						
		</Box>	
	</Grid>
						
					
	<Grid item xs={12}>
			<Box display="flex" flexDirection="row-reverse" >
						
			<Button variant="contained" size="large"  
			color="secondary"  
			fullWidth
			onClick={()=>{this.addUser(academy_info[0],academy_info[1],'student')}}>
			As User
			</Button>
						
			</Box>
	</Grid>
	</Grid>
	)}	
	
	
		const DialogForm = () =>
		{
			const border={'border-radius':'12px'}

			return(
	
		    <Dialog
			 open={this.state.dialog_op} 
			  onClose={this.handleClose}
			fullWidth style={border}
			maxWidth="xs">	 
			<DialogContent dividers>
			<Box style={{backgroundColor:'white',width:'100%'}} >	
			<Box display="flex" flexDirection="row-reverse"   padding={2}    paddingBottom={2} >
			<IconButton
			onClick={this.handleClose}>
						<CloseIcon color="grey"  style={{fontSize:"0.85rem"}} />
			 </IconButton>
			</Box>
			
			
			 <Box paddingLeft={2} paddingRight={2} style={{ backgroundColor:"#fff",padding:'0px 16px 16px'}}>
		
			<Card elevation={0} style={{padding:'0px'}}>
			<CardContent style={{padding:'0px 16px 16px 16px'}}>
			<AcReg/>
		   </CardContent>	
			</Card>	
			</Box>	
			
			</Box >	
			</DialogContent>
		  </Dialog>
			
			)
			
		}
  
		return(
		<Box>
		{
				this.state.dialogopen && (
				<AlertDialog
				  text="I apologize for the inconvenience. It appears that your registration is for a webinar and not an academy profile. Consequently, the functionality related to the academy is currently unavailable to you. To access the desired features, I kindly request you to register on the academy platform using a different ID."
				/>
			  )
		}
		{
		  this.state.msg!=false
		  ?	  <AlertBox show_status={true} msg={this.state.msg}
		  msg_type={this.msg_type==='warning'?'warning':"success"} />
		  :''
	    }

		<DialogForm/>
		<WebCard/>
		</Box>
        );
  
    }
}
export default (props) => {
    return (
        <Social  requestFor={props.requestFor} user={props.user} />
    )
}





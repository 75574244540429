import React, {useState} from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useGlobalStyles from '../styles/CourseStyles';
import benefit1 from '../assets/Venture/venture_image1.png';
import benefit2 from '../assets/Venture/add_icon.png';
import benefit3 from '../assets/Venture/venture_image2.jpeg';
import benefit4 from '../assets/Venture/venture_image3.svg';
import { PopupButton } from 'react-calendly';

const useStyles = makeStyles((theme) => ({
  container: {
    border: 'none',
    padding: theme.spacing(4),
    marginTop: theme.spacing(6),
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
  },
  headerBadge: {
    backgroundColor: '#FFEB3B',
    color: 'black',
    borderRadius: '50px',
    padding: theme.spacing(1, 3),
    display: 'inline-block',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  mainContent: {
    width: '70%',
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
    },
  },
  title: {
    fontWeight: 'bold',
    color: '#003366',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: '#25387c',
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    fontWeight: 'bold',
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  featureList: {
    marginBottom: theme.spacing(3),
  },
  featureItem: {
    marginBottom: theme.spacing(2),
    color: '#333',
  },
  imagesContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: '#f9bb02', // Yellow background
    padding: theme.spacing(2),
    borderRadius: '8px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  featureImage: {
    width: '100%',
    maxWidth: '75px',
    height: 'auto',
    // Optional: Adjust opacity or blend mode for better blending
    opacity: 0.9,
    mixBlendMode: 'multiply',
  },
  getStartedButton: {
    backgroundColor: '#FFEB3B',
    color: 'black',
    fontWeight: 'bold',
    padding: theme.spacing(1.5, 4),
    '&:hover': {
      backgroundColor: '#FFD700',
    },
  },
  whatsIncludedBox: {
    backgroundColor: '#003366',
    color: '#fff',
    padding: theme.spacing(3),
    borderRadius: '8px',
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      marginTop: theme.spacing(4),
    },
  },
  whatsIncludedTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    fontSize: '1.25rem',
  },
  listItem: {
    marginBottom: theme.spacing(1),
    color: '#fff',
  },
  ul: {
    paddingLeft: theme.spacing(2),
    color: '#fff',
    '& li': {
      marginBottom: theme.spacing(0.5),
    },
  },
  promoButton : {
    padding: theme.spacing(1, 4),
  }
}));

const VenturePricingPlan = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const [calendlyUrl] = useState('https://calendly.com/vedika-5m0y/30min?month=2024-10');

  return (
    <Box className={classes.container}>

      {/* Main Row Container */}
      <Box className={classes.rowContainer}>
        {/* Left Column - Main Content */}
        <Box className={classes.mainContent}>
          {/* Title */}
          <Typography variant="h4" className={styleClasses.h4font}>
          Venture Assistance Pricing Plan
          </Typography>

          {/* Subtitle */}
          <Typography variant="subtitle1" className={`${classes.subtitle} ${styleClasses.subtitlefont}`}>
          Empower Your Startup with a Fully Customizable Growth Solution
          </Typography>

          {/* Section Title */}
          <Typography variant="h6" className={`${classes.sectionTitle} ${styleClasses.description}`}>
          Why Choose the Venture Plan?
          </Typography>

          {/* Feature List */}
          <Box className={classes.featureList}>
            <Typography className={styleClasses.body1font}>
              <strong className={styleClasses.subtitlefont}>Scalable & Flexible:</strong> Adjust services and hours as your business evolves.
            </Typography>
            <Typography className={styleClasses.body1font}>
              <strong className={styleClasses.subtitlefont}>End-to-End Support:</strong> From strategy to execution, we cover every aspect of your growth journey.
            </Typography>
            <Typography className={styleClasses.body1font}>
              <strong className={styleClasses.subtitlefont}>Expert-Driven Results:</strong> Benefit from the combined expertise of industry leaders and growth specialists.
            </Typography>
          </Box>

          {/* Images */}
          <Box className={classes.imagesContainer}>
            <img
              src={benefit1}
              alt="Benefit 1"
              className={classes.featureImage}
            />
            <img
              src={benefit2}
              alt="Benefit 2"
              className={classes.featureImage}
            />
            <img
              src={benefit3}
              alt="Benefit 3"
              className={classes.featureImage}
            />
            <img
              src={benefit2}
              alt="Benefit 2 Again"
              className={classes.featureImage}
            />
            <img
              src={benefit4}
              alt="Benefit 4"
              className={classes.featureImage}
            />
          </Box>
          <Typography variant="subtitle2" className={styleClasses.subtitle2font} gutterBottom>
          Schedule a consultation today to create your Venture Plan and take your business to the next level!
          </Typography>

          {/* Get Started Button */}
          <Box textAlign="left">
          <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Get Started"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
          </Box>
        </Box>

        {/* Right Column - What's Included */}
        <Box className={classes.whatsIncludedBox}>
          <Typography variant="h6" className={styleClasses.h5fontPara} gutterBottom>
            What's Included?
          </Typography>
          <Typography className={styleClasses.body1font} gutterBottom>
            <strong className={styleClasses.subtitlefont} style={{ color: '#fff' }}>Expert Consultation:</strong> Access seasoned industry leaders for strategic guidance.
          </Typography>
          <br/>
          <Typography className={styleClasses.body1font} gutterBottom>
            <strong className={styleClasses.subtitlefont} style={{ color: '#fff' }}>Growth Associate:</strong> Get dedicated support for day-to-day business growth tasks.
          </Typography>
          <br/>
          <Typography className={styleClasses.body1font} gutterBottom>
            <strong className={styleClasses.subtitlefont} style={{ color: '#fff' }}>Custom Services:</strong> Choose from a range of services including:
          </Typography>
          <br/>
          <ul className={classes.ul}>
            <li>Marketing Strategy & Execution</li>
            <li>Sales Support & Lead Generation</li>
            <li>Business Development & Operations</li>
            <li>Content Creation & Digital Campaigns</li>
            <li>Data Analysis & Reporting</li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default VenturePricingPlan;

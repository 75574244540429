// src/components/BookCallForm.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography,
    Avatar,
    makeStyles,
    useTheme,
    useMediaQuery,
    Grid,
    Box,
    IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MuiPhoneNumber from "material-ui-phone-number";
import useGlobalStyles from "../styles/CourseStyles";
import useExpertStyles from "../styles/ExpertStyles";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    dialogActions: {
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(2),
    },
    submitButton: {
        backgroundColor: "#f9bb02",
        color: "black",
        fontWeight: 600,
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#f9bb02",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
    },
    textField: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.grey[400],
            },
            "&:hover fieldset": {
                borderColor: theme.palette.primary.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
            },
            "&.Mui-error fieldset": {
                borderColor: theme.palette.error.main,
            },
        },
        "& .MuiFormHelperText-root.Mui-error": {
            color: theme.palette.error.main,
        },
    },
    summarySection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    expertAvatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        marginBottom: theme.spacing(1),
    },
    expertName: {
        fontWeight: 600,
        textAlign: "center",
    },
    expertIndustry: {
        color: theme.palette.text.secondary,
        textAlign: "center",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const BookCallForm = ({ open, onClose, expertAvatar, expertName, expertIndustry }) => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const expertClasses = useExpertStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });

    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (!document.getElementById("calendly-script")) {
            const script = document.createElement("script");
            script.id = "calendly-script";
            script.src = "https://assets.calendly.com/assets/external/widget.js";
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);


    const validateField = (name, value) => {
        const emailRegex = /^(?!-)(?!.*\.\.)[A-Za-z0-9._%+]+(?<!\.)@(?!-)(?!.*\.\.)[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        let error = "";
        if (name === "firstName") {
            if (!value.trim()) {
                error = "First name is required";
            }
        } else if (name === "lastName") {
            if (!value.trim()) {
                error = "Last name is required";
            }
        } else if (name === "email") {
            if (!value.trim()) {
                error = "Email is required";
            } else if (!emailRegex.test(value)) {
                error = "Email is invalid";
            }
        } else if (name === "phone") {
            // Remove non-digit characters before validation
            const digits = value.replace(/\D/g, '');
            if (digits && !/^\d{10}$/.test(digits)) {
                error = "Phone number must be 10 digits";
            }
        }
        return error;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (formErrors[name]) {
            const error = validateField(name, value);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                [name]: error,
            }));
        }
    };

    const handlePhoneChange = (value) => {
        setFormData((prevState) => ({
            ...prevState,
            phone: value,
        }));

        if (formErrors.phone) {
            const error = validateField("phone", value);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                phone: error,
            }));
        }
    };

    const validateForm = () => {
        let errors = {};
        Object.keys(formData).forEach((field) => {
            const error = validateField(field, formData[field]);
            if (error) {
                errors[field] = error;
            }
        });
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const openCalendlyPopup = () => {
        if (window.Calendly) {
            window.Calendly.initPopupWidget({
                url: 'https://calendly.com/opengrowth-support/30min',
                prefill: {
                    name: `${formData.firstName} ${formData.lastName}`,
                    email: formData.email,
                    // Optionally, you can add phone if Calendly supports it
                    // phone: formData.phone,
                },
                utm: {
                    expert_name: expertName,
                    expert_industry: expertIndustry,
                },
            });
        } else {
            console.error("Calendly script not loaded");
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Form Data:", formData);

            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
            });
            setFormErrors({});
            onClose();
            openCalendlyPopup();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="form-dialog-title">
                Book a Discovery Call
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <Box className={classes.summarySection}>
                    <Avatar
                        alt={expertName}
                        src={expertAvatar}
                        className={classes.expertAvatar}
                    />
                    <Typography variant="h6" className={styleClasses.h6font}>
                        {expertName}
                    </Typography>
                    <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
                        {expertIndustry}
                    </Typography>
                </Box>
                <form onSubmit={handleFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="First Name"
                                name="firstName"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                error={!!formErrors.firstName}
                                helperText={formErrors.firstName}
                                required
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Last Name"
                                name="lastName"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                error={!!formErrors.lastName}
                                helperText={formErrors.lastName}
                                required
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                fullWidth
                                variant="outlined"
                                value={formData.email}
                                onChange={handleInputChange}
                                error={!!formErrors.email}
                                helperText={formErrors.email}
                                required
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPhoneNumber
                                defaultCountry={"us"}
                                label="Phone Number"
                                variant="outlined"
                                fullWidth
                                name="phone"
                                value={formData.phone}
                                onChange={handlePhoneChange}
                                error={!!formErrors.phone}
                                helperText={formErrors.phone}
                                className={classes.textField}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button onClick={onClose} className={`${styleClasses.businessButton}`}>
                    Cancel
                </Button>
                <Button
                    onClick={handleFormSubmit}
                    className={`${styleClasses.individualButton} ${expertClasses.popupButton} ${classes.submitButton}`}
                    variant="contained"
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
};

BookCallForm.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    expertAvatar: PropTypes.string.isRequired,
    expertName: PropTypes.string.isRequired,
    expertIndustry: PropTypes.string.isRequired,
};

export default BookCallForm;

// src/data/coursesData.js

const courses = [
    {
        id: 'prospective-expert',
        title: 'Prospective Fractional Experts Guide',
        category: 'Leadership',
        duration: '2 Weeks',
        description: "This course is aimed at professionals looking to break into the gig economy at an executive level and share their knowledge and expertise to benefit organizations, on a part-time basis instead of being tied down to one source of income. You will learn what it takes to position yourself as an executive for hire and work on your terms, setting up a business and building your brand. Establish your clientele and service them with considerable ease, all from the comfort of your home. Create an impact and earn the admiration and respect of others, who will spread your brand further afield. Interact with fractional executives who are already running their businesses, and learn how to establish a smooth work-life balance while profiting from varied sources of income. Learn how to market yourself, build your online presence, and develop a tech stack to accomplish your tasks with considerable ease. Find out, how you, can develop your business entity, finance it, and grow it.",
        imageUrl: 'https://academy.opengrowth.com/assets/images/courses/thumb_abc.jpeg',
        avatar: [
            'https://academy.opengrowth.com/assets/images/users/user_523_professor_DheerajP.png',
        ],
        instructors: [
            { name: 'Dheeraj P' },
        ],
        ratings: 5,
        reviews: 1,
        whatYouWillLearn: [
            'Understand the concept and importance of MVP in product development',
            'Develop a compelling problem statement',
            'Conduct effective market research',
            'Define and prioritize product features',
            'Analyze user feedback to iterate and improve your product'
        ],
        price: 'Free',
        includes: [
            '3-5 hours on-demand video',
            '3 downloadable resources',
            'Full lifetime access',
            'Access on mobile and TV',
            'Certificate of completion'
        ],
        requirements: [
            'Basic understanding of product development',
            'Access to the internet',
            'Commitment to completing the course within 1 month'
        ],
        type: "individual"
    },
    {
        id: 'mvp-fundamentals',
        title: 'How to Plan an MVP',
        category: 'Marketing',
        duration: '1 Month',
        description: "One of the most effective approaches to product development is a Minimum Viable Product (MVP) to ensure that the product you are building is validated and verified by users, increasing its chances of success exponentially. An MVP uses the Build-Measure-Learn cycle, an integral part of Eric Ries' Lean Startup business model which has already won the approval of hundreds of thousands of entrepreneurs worldwide. This course will introduce you to the concept of MVP, including the term's origin, how it has risen to popularity, and why it is crucial for business and marketing efforts. Next, you will learn how to develop an MVP from the ground up, including establishing a compelling problem statement, conducting market research, finding a potential solution to the drawn problem, defining the features of your product, and iterations. You will also learn how to analyze and extract insights from the feedback you collect during the launch of your MVP and while moving to a full-scaled product.",
        imageUrl: 'https://academy.opengrowth.com/assets/images/courses/thumb_mvpnew.jpg',
        avatar: [
            'https://randomuser.me/api/portraits/men/75.jpg',
            'https://randomuser.me/api/portraits/women/65.jpg'
        ],
        instructors: [
            { name: 'John Doe' },
            { name: 'Jane Smith' }
        ],
        ratings: 5,
        reviews: 1,
        whatYouWillLearn: [
            'Understand the concept and importance of MVP in product development',
            'Develop a compelling problem statement',
            'Conduct effective market research',
            'Define and prioritize product features',
            'Analyze user feedback to iterate and improve your product'
        ],
        price: 'Free',
        includes: [
            '3-5 hours on-demand video',
            '3 downloadable resources',
            'Full lifetime access',
            'Access on mobile and TV',
            'Certificate of completion'
        ],
        requirements: [
            'Basic understanding of product development',
            'Access to the internet',
            'Commitment to completing the course within 1 month'
        ],
        type: "business"
    },
    {
        id: 'competitor-analysis',
        title: 'Competitor Analysis',
        category: 'Leadership',
        duration: '1 Month',
        description: "To enter and operate your startup in a market, you first need to scope out the competition. This involves listing and researching each of your competitors intricately and exhaustively. This exercise is fundamental to your success and requires your full attention. In this course, you will learn how to conduct a market analysis and derive insights from it about your competitive market, carry out strategic analysis to understand your competitors' strategies, formulate your strategies for yourself, and conduct a competitor analysis. A truly comprehensive competitor analysis includes understanding the competitor market, identifying your competitors, performing a self-evaluation based on your market analysis, and determining your competitors' positioning in the market.",
        imageUrl: 'https://academy.opengrowth.com/assets/images/courses/thumb_competitor-analysisnew.jpg',
        avatar: ['https://academy.opengrowth.com/assets/images/courses/thumb_abc.jpeg'],
        instructors: [
            { name: 'Alice Johnson' }
        ],
        ratings: 4,
        reviews: 2,
        whatYouWillLearn: [
            'Identify and analyze your main competitors',
            'Understand market positioning and competitive strategies',
            'Conduct SWOT analysis for your business',
            'Develop strategies to gain a competitive edge',
            'Implement effective monitoring of competitor activities'
        ],
        price: '$49.99',
        includes: [
            '4 hours on-demand video',
            '5 downloadable resources',
            'Full lifetime access',
            'Access on mobile and TV',
            'Certificate of completion'
        ],
        requirements: [
            'Basic knowledge of market research',
            'Access to a computer with internet',
            'Willingness to engage in strategic planning exercises'
        ],
        type: "business"
    },
    {
        id: 'pitch-your-startup',
        title: 'How to Pitch Your Startup',
        category: 'Leadership',
        duration: '3 Months',
        description: "Having a great idea is only half the recipe for establishing and running a successful startup. A considerable part of startup success lies in being able to persuade people to join you or buy into your idea or company. Whether it's customers, employees, partners, or investors you want to woo, pitching is the key skill you need to master to make things happen. Essentially people evaluate your business idea, product, or service through you, therefore it is imperative to make a lasting impression using your charm and personality whilst providing conclusive data or research to back your claims. This course will help you identify what you need to pitch about, research the facts thoroughly and finally create and deliver a captivating and engaging pitch in order to clinch the deal.",
        imageUrl: 'https://academy.opengrowth.com/assets/images/courses/thumb_adistel.jpg',
        avatar: ['https://randomuser.me/api/portraits/women/66.jpg'],
        instructors: [
            { name: 'Bob Williams' }
        ],
        ratings: 4,
        reviews: 0,
        whatYouWillLearn: [
            'Structuring your business idea and categorising your area of work',
            'Conducting market research to back up your presentations with hard evidence',
            'Analyzing your startup position',
            'Mastering public speaking to connect with investors',
            'Evaluating your financial situation and present condition',
            'Creating your business development plan',
            'Measuring your finances',
            'Evaluating your financial situation and present condition',
        ],
        price: '$99.99',
        includes: [
            '10 hours on-demand video',
            '5 downloadable resources',
            'Full lifetime access',
            'Access on mobile and TV',
            'Certificate of completion'
        ],
        requirements: [
            'Basic presentation skills',
            'Access to presentation software (e.g., PowerPoint)',
            'Commitment to practicing your pitch regularly'
        ],
        type: "both"
    },
    {
        id: 'business-modeling',
        title: 'Business Modeling through Strategy and Analysis',
        category: 'Product',
        duration: '3 Months',
        description: "This course will give you a complete overview of developing and designing a business model that suits your business. Every startup/organization needs a basic structure/model to keep going, and this course will help you develop one. You can also avail case studies and analyze business models already in use, that have been successfully implemented in many organizations. Learn how to experiment with and analyze business models to derive foolproof strategies to steer your business to success.",
        imageUrl: 'https://academy.opengrowth.com/assets/images/courses/thumb_Strategy-and-Analysis.jpg',
        avatar: ['https://randomuser.me/api/portraits/women/67.jpg'],
        instructors: [
            { name: 'Carol Martinez' }
        ],
        ratings: 3,
        reviews: 1,
        whatYouWillLearn: [
            'Develop and design effective business models',
            'Analyze successful business models through case studies',
            'Experiment with different business strategies',
            'Align your business model with market needs',
            'Implement strategies to drive business success'
        ],
        price: 'Free',
        includes: [
            '12 hours on-demand video',
            '6 downloadable resources',
            'Full lifetime access',
            'Access on mobile and TV',
            'Certificate of completion'
        ],
        requirements: [
            'Basic understanding of business concepts',
            'Access to a computer with internet',
            'Willingness to engage in strategic analysis'
        ],
        type: "both"
    },
    {
        id: 'founder-dynamics',
        title: 'Founder and Co-Founder Dynamics',
        category: 'Leadership',
        duration: '1 Month',
        description: "This course explores the relationships between founders and co-founders, including behavioral cues to watch out for when trying to woo a suitable co-founder for your startup. Apart from the technical, commercial or other expertise and even investment the co-founder brings to the table, it is important to understand how to choose the right co-founder for your organization. This course will give you a complete insight on a co-founder's duties, responsibilities, liabilities and attributes.",
        imageUrl: 'https://academy.opengrowth.com/assets/images/courses/thumb_co-founder-dynamicsnew.jpeg',
        avatar: ['https://randomuser.me/api/portraits/women/67.jpg'],
        instructors: [
            { name: 'David Lee' }
        ],
        ratings: 5,
        reviews: 9,
        whatYouWillLearn: [
            'Identify key traits of effective co-founders',
            'Understand the roles and responsibilities of co-founders',
            'Navigate the legal aspects of co-founding a startup',
            'Build strong working relationships with your co-founder',
            'Resolve conflicts and ensure long-term collaboration'
        ],
        price: '$59.99',
        includes: [
            '5 hours on-demand video',
            '4 downloadable resources',
            'Full lifetime access',
            'Access on mobile and TV',
            'Certificate of completion'
        ],
        requirements: [
            'Existing startup or idea',
            'Access to a computer with internet',
            'Commitment to building a co-founding relationship'
        ],
        type: "individual"
    },
    {
        id: 'content-marketing-essentials',
        title: 'Content Marketing Essentials',
        category: 'Product',
        duration: '5 Weeks',
        description: "Content marketing is at the forefront of marketing today, with inbound marketing taking over from traditional outbound marketing techniques such as, TV adverts, print media, radio, and even internet adverts which distract and disturb audiences. This course helps you understand the why, what and how of content marketing, helping you learn how to recognize, create or order crisp, engaging and compelling content to woo your customers. Learn how to tell captivating stories, creating effective and relevant content to reach out to audiences beyond your locale using the internet, a staple in almost every household today. Learn how to use powerful Content Marketing techniques, strategies, frameworks, tools and platforms to ensure your content delivers the results you want.",
        imageUrl: 'https://academy.opengrowth.com/assets/images/courses/thumb_contentmarketingnew.jpg',
        avatar: ['https://randomuser.me/api/portraits/women/67.jpg'],
        instructors: [
            { name: 'Evelyn King' }
        ],
        ratings: 4,
        reviews: 1,
        whatYouWillLearn: [
            'Understand the fundamentals of content marketing',
            'Create engaging and relevant content for your audience',
            'Develop effective content marketing strategies',
            'Utilize content marketing tools and platforms',
            'Measure and analyze the performance of your content'
        ],
        price: 'Free',
        includes: [
            '6 hours on-demand video',
            '3 downloadable resources',
            'Full lifetime access',
            'Access on mobile and TV',
            'Certificate of completion'
        ],
        requirements: [
            'Basic marketing knowledge',
            'Access to content creation tools',
            'Willingness to create and distribute content regularly'
        ],
        type: "both"
    },
    {
        id: 'mvp-fundamentals-2',
        title: 'MVP Fundamentals',
        category: 'Leadership',
        duration: '5 Weeks',
        description: 'In this course, you will understand the fundamentals of MVPs and their significance in product development. The course commences with an introduction to MVPs, elucidating their concept and purpose. You will learn about the build, measure, learn cycle, which is the foundation for effectively constructing MVPs. The course delves into measuring MVP results, emphasizing the importance of metrics and gathering feedback to gauge MVP success accurately.',
        imageUrl: 'https://academy.opengrowth.com/assets/images/courses/thumb_10HowtomeasurePMF.jpg',
        avatar: ['https://randomuser.me/api/portraits/women/67.jpg'],
        instructors: [
            { name: 'Frank Moore' }
        ],
        ratings: 5,
        reviews: 1,
        whatYouWillLearn: [
            'Learn the essentials of incorporating a company',
            'Understand the legal and financial implications',
            'Develop strategies for sustainable growth',
            'Navigate the challenges of early-stage startups',
            'Implement best practices for business operations'
        ],
        price: '$79.99',
        includes: [
            '8 hours on-demand video',
            '4 downloadable resources',
            'Full lifetime access',
            'Access on mobile and TV',
            'Certificate of completion'
        ],
        requirements: [
            'Basic business knowledge',
            'Access to legal and financial resources',
            'Commitment to following a structured incorporation process'
        ],
        type: "both"
    },
    {
        id: 'hr-remote-teams',
        title: 'HR for Remote Teams (HRD & HRM)',
        category: 'HR',
        duration: '5 Weeks',
        description: 'A bulletproof Human Resource Development and Human Resource Management...',
        imageUrl: 'https://academy.opengrowth.com/assets/images/courses/thumb_hrnew.jpg',
        avatar: ['https://randomuser.me/api/portraits/women/67.jpg'],
        instructors: [
            { name: 'Grace Kim' }
        ],
        ratings: 5,
        reviews: 1,
        whatYouWillLearn: [
            'Develop effective HR strategies for remote teams',
            'Manage remote employees efficiently',
            'Implement HRD and HRM best practices',
            'Handle recruitment, onboarding, and retention remotely',
            'Ensure compliance with labor laws and regulations for remote work'
        ],
        price: 'Free',
        includes: [
            '7 hours on-demand video',
            '5 downloadable resources',
            'Full lifetime access',
            'Access on mobile and TV',
            'Certificate of completion'
        ],
        requirements: [
            'Basic understanding of HR principles',
            'Access to HR management tools',
            'Willingness to adapt to remote work environments'
        ],
        type: "business"
    },
];

export default courses;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Card,
  CardContent,
  TextField,
  Button,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert'; // For the Alert component
import useGlobalStyles from '../styles/CourseStyles';

// Alert component for Snackbar
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(4, 10),
  },
  titleContainer: {
    textAlign: 'left',
    marginBottom: theme.spacing(4),
  },
  h3font: {
    color: '#25387c',
    textAlign: 'left',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  h4font: {
    fontWeight: 400,
  },
  subtitle: {
    color: '#000',
    marginTop: theme.spacing(1),
    fontSize: '1.1rem',
  },
  locationsContainer: {
    margin: '0 auto',
    padding: theme.spacing(2, 0),
  },
  locationHeading: {
    fontWeight: 600,
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  leftBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingRight: 0,
    },
  },
  officeTitle: {
    fontWeight: 400,
    color: '#25387c',
    padding: '2px 0px',
    marginBottom: theme.spacing(1),
  },
  officeText: {
    color: '#25387c',
    lineHeight: 1.6,
  },
  contactCard: {
    backgroundColor: '#25387c',
    color: '#fff',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  getintouchTitle: {
    fontWeight: 700,
    color: '#fff',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  contactText: {
    color: '#fff',
    marginBottom: theme.spacing(1.5),
  },
  mapContainer: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  iframe: {
    width: '100%',
    minHeight: '300px',
    border: 0,
  },

  /* New form styles */
  formContainer: {
    marginTop: theme.spacing(6),
  },
  formTitle: {
    color: '#25387c',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  formSubtitle: {
    color: '#000',
    marginBottom: theme.spacing(3),
  },
  textField: {
    backgroundColor: '#fafafa',
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#ccc',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#25387c',
      },
    },
  },
  submitButton: {
    marginTop: theme.spacing(1.5),
    backgroundColor: '#25387c',
    color: '#fff',
    padding: theme.spacing(1.5, 3),
    textTransform: 'none',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#1f2f63',
    },
  },
  form: {
    textAlign: "center",
  }
}));

const ContactUs = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();

  useEffect(() => {
    const mainTitle = "Contact Us | OpenGrowth.AI";
    const subtitle = "Get in touch with OpenGrowth.AI for personalized guidance and AI-powered solutions to drive your business growth. We're here to help!";
    document.title = `${mainTitle} - ${subtitle}`;
  }, []);

  // State for form fields
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: '',
  });

  // State for form errors
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    message: '',
  });

  // State for Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form values
    setFormValues({
      ...formValues,
      [name]: value,
    });

    // Clear error for the field
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  };

  // Validate email format
  const validateEmail = (email) => {
    // Simple email regex
    const re = /^[A-Za-z0-9]+@[A-Za-z]+\.[A-Za-z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const nameRegex = /^[A-Za-z\s]+$/;

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};
    let isValid = true;

    // Validate Name
    if (!formValues.name.trim()) {
      errors.name = 'Name is required';
      isValid = false;
    } else if (!nameRegex.test(formValues.name.trim())) {
      errors.name = 'Full Name can only contain letters and spaces';
      isValid = false;
  }

    // Validate Email
    if (!formValues.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!validateEmail(formValues.email)) {
      errors.email = 'Please enter a valid email address (e.g., user123@example.com)';
      isValid = false;
    }

    // Validate Message
    if (!formValues.message.trim()) {
      errors.message = 'Message is required';
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      // Here you can handle the form submission, e.g., send data to a server

      // Reset form
      setFormValues({
        name: '',
        email: '',
        message: '',
      });

      // Show success Snackbar
      setOpenSnackbar(true);
    }
  };

  // Handle Snackbar close
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box className={classes.root}>

      {/* Title & Subtitle */}
      <Box className={classes.titleContainer}>
        <Typography variant="h3" className={`${styleClasses.h3font} ${classes.h3font}`}>
          Contact Us
        </Typography>
        <Typography variant="h6" className={styleClasses.h6banner}>
          We’d love to hear from you! Whether you have a question, need Growth Experts or want to explore our services, we’re here to help.
        </Typography>
      </Box>

      <Grid container spacing={4} className={classes.locationsContainer}>
        {/* Left column: Offices */}
        <Grid item xs={12} md={8} style={{ padding: '16px 0px' }}>
          <Typography variant="h4" className={`${styleClasses.h4font} ${classes.h4font}`}>
            Our Location:
          </Typography>
          <Box className={classes.leftBox}>
            <Box mb={2}>
              <Typography variant="h6" className={`${styleClasses.h6font} ${classes.officeTitle}`}>
                Head Office
              </Typography>
              <Typography
                variant="subtitle1"
                className={`${styleClasses.subtitlefont} ${classes.officeText}`}
              >
                1280 Lincoln Ave
                <br />
                Palo Alto, CA 94301
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h6" className={`${styleClasses.h6font} ${classes.officeTitle}`}>
                Reg Office
              </Typography>
              <Typography
                variant="subtitle1"
                className={`${styleClasses.subtitlefont} ${classes.officeText}`}
              >
                401, Sharan Vihar Apts,
                <br />
                New PP Colony, Patna, Bihar-800013
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Right column: Get in Touch Card */}
        <Grid item xs={12} md={4}>
          <Card elevation={3} className={classes.contactCard}>
            <CardContent>
              <Typography
                variant="h4"
                className={`${styleClasses.h4font} ${classes.getintouchTitle}`}
              >
                Get in Touch
              </Typography>
              <Typography
                variant="subtitle1"
                className={`${styleClasses.body1font} ${classes.contactText}`}
              >
                <strong style={{ color: '#fff' }}>📧 Email: </strong> contact@opengrowth.com
              </Typography>
              <Typography
                variant="subtitle1"
                className={`${styleClasses.body1font} ${classes.contactText}`}
              >
                <strong style={{ color: '#fff' }}>☎️ Phone: </strong> 09082547705
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Map Section */}
      <Box className={classes.mapContainer}>
        {/* Replace the src with your own Google Maps embed link or an <img> of the map */}
        <iframe
          title="Office Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.3686091857674!2d-122.14884648448455!3d37.44860243965432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fbb293dba0505%3A0xccd07ff262444d3b!2s1280%20Lincoln%20Ave%2C%20Palo%20Alto%2C%20CA%2094301%2C%20USA!5e0!3m2!1sen!2sin!4v1676044990708!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          className={classes.iframe}
        />
      </Box>

      {/* New Form Section - Below the Map */}
      <Box className={classes.formContainer}>
        <Typography variant="h4" className={styleClasses.h4font}>
          Send Us a Message
        </Typography>
        <Typography variant="h6" className={styleClasses.description} gutterBottom>
          Have a specific inquiry? Use the form below to get in touch with us.
        </Typography>

        <form noValidate autoComplete="off" onSubmit={handleSubmit} className={classes.form}> 
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Name *"
            required
            name="name"
            value={formValues.name}
            onChange={handleChange}
            className={classes.textField}
            InputLabelProps={{ shrink: false }}
            error={Boolean(formErrors.name)}
            helperText={formErrors.name}
          />
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Email *"
            required
            name="email"
            value={formValues.email}
            onChange={handleChange}
            className={classes.textField}
            InputLabelProps={{ shrink: false }}
            error={Boolean(formErrors.email)}
            helperText={formErrors.email}
          />
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Message *"
            required
            multiline
            rows={5}
            name="message"
            value={formValues.message}
            onChange={handleChange}
            className={classes.textField}
            InputLabelProps={{ shrink: false }}
            error={Boolean(formErrors.message)}
            helperText={formErrors.message}
          />
          <Button
            variant="contained"
            type="submit"
            className={styleClasses.businessButton} // Updated to use classes.submitButton
          >
            Submit
          </Button>
        </form>
      </Box>

      {/* Success Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Your message has been sent successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactUs;

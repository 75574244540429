// src/components/ExpertProfile.jsx

import React, { useMemo, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Button,
  Avatar,
  Box,
  Chip,
  Paper,
  List,
  ListItem,
  ListItemText,
  TextField
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import expert_profile from '../assets/expert_profile_2.jpg'; // Replace with your actual image
// import TrendingBlogs from './TrendingBlogs';
import { manualExperts } from './ManualExperts'; // Adjust the path as needed
import TrendingBlogs from './TrendingBlogs';
import { PopupWidget } from 'react-calendly';
import { PopupButton } from 'react-calendly';
import useGlobalStyles from '../styles/CourseStyles';
import useExpertStyles from '../styles/ExpertStyles';
import BookCallForm from '../Homepage/DiscoveryCallFormDialog';
import { blogData } from '../Homepage/BlogData';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  resetOverflow: {
    margin: 0,
    padding: 0,
    overflowX: 'hidden', // Hide horizontal overflow
  },
  headerText: {
    maxWidth: '100%',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    },
  },

  avatarSection: {
    position: 'absolute',
    top: 15,
    left: '83%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide on small screens
    },
  },
  backButton: {
    marginBottom: theme.spacing(2),
  },
  noExpert: {
    textAlign: 'center',
    padding: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  // New styles for responsive typography
  h2: {
    color: 'white',
    marginBottom: '4px',
    '&:after': {
      border: 'none',
      content: 'none',
    },
  },
  h4: {
    color: '#000',
    fontWeight: '500',
    marginBottom: '0px',
    marginTop: '8px',
  },
  h5: {
    color: 'white',
    fontWeight: '500',
  },
  h6font: {
    color: 'white',
    fontWeight: '500',
  },
  chipContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', // Stack chips vertically on small screens
      alignItems: 'left', // Optional: Center chips horizontally
    },

  },
  Box: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      justifyContent: 'space-between',
    },
  },
  expertAbout: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
    },
  },
  offerText: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
    },
  },
  ctaTitle: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8rem',
    },
  },
  ctaBody: {
    marginTop: '8px',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
    },
  },
  additionalCTATitle: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  },
  additionalCTABody: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.95rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  additionalCTAButton: {
    marginTop: '24px',
    borderRadius: '30px',
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: '#f9bb02',
    color: 'black',
    fontSize: '1rem',
    padding: '8px 16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      padding: '6px 12px',
    },
    '&:hover': {
      backgroundColor: '#e0a800', // Optional: Darken button on hover
    },
  },
  buttonbox: {
    gap: 12,
  },
  individualButton: {
    marginRight: '40px !important',
    marginTop: '10px'
  },
  reviewTextField: {
    marginBottom: '1rem',
  },
  button1: {
    backgroundColor: '#25387c',
    borderRadius: '50px',
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: '600',
    padding: '0px 16px',
    fontFamily: 'MyCustomFont, sans-serif',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: '#25387c',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      padding: '6px 6px',
    },
  }
}));

// Combined AboutSection with OffersSection
const AboutSection = ({ about, offers, styleClasses, expertClasses }) => {

  return (
    <Box className={expertClasses.aboutSection}>
      {/* About Me Section */}
      <Typography variant="h4" className={`${styleClasses.h4font} `} gutterBottom>
        About Me
      </Typography>
      {Array.isArray(about) ? (
        <List dense>
          {about.map((point, index) => (
            <ListItem key={index} alignItems="flex-start" style={{padding: '8px 0px'}}>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
                    {point}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1" className={styleClasses.subtitlefont}>
          {about}
        </Typography>
      )}

      {/* Offers Section */}
      <Box className={expertClasses.offersSection}>
        <Typography variant="h5" gutterBottom className={`${styleClasses.h5heading} ${expertClasses.h5}`}>
          Things I Can Offer Advice On
        </Typography>
        {offers && offers.length > 0 ? (
          <List dense>
            {offers.map((offer, index) => (
              <ListItem key={index} alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
                      {offer}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
            No offerings available at the moment.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

// New ClientReviewsSection Component with Description Card
const ClientReviewsSection = ({ classes, styleClasses, expertClasses }) => {
  const history = useHistory(); // Add history for navigation if needed
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [reviewText, setReviewText] = useState('');
  const [reviews, setReviews] = useState([
    // Initial mock reviews (can be fetched from a backend in a real app)
    {
      author: 'John Smith',
      text: 'Great expert! Highly recommend their services.',
      date: '2024-04-20',
    },
    {
      author: 'Emily Johnson',
      text: 'Very insightful and professional.',
      date: '2024-04-18',
    },
  ]);

  const handleAddReviewClick = () => {
    setShowReviewForm(true);
  };

  const handleCancelReview = () => {
    setShowReviewForm(false);
    setReviewText('');
  };

  const handleReviewSubmit = (e) => {
    e.preventDefault();
    if (reviewText.trim() === '') return;

    const newReview = {
      author: 'Anonymous', // In a real app, replace with authenticated user's name
      text: reviewText.trim(),
      date: new Date().toISOString().split('T')[0], // Format: YYYY-MM-DD
    };

    setReviews([newReview, ...reviews]);
    setReviewText('');
    setShowReviewForm(false);
  };

  return (
    <Box className={expertClasses.clientReviews}>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" className={classes.Box}>
        <Typography variant="h5" gutterBottom className={`${styleClasses.h5heading} ${expertClasses.h5}`}>
          Client Reviews of Our Experts
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={styleClasses.businessButton}
          onClick={handleAddReviewClick}
        >
          Add Review
        </Button>
      </Box>

      {/* Review Form */}
      {showReviewForm && (
        <Box className={classes.reviewForm}>
          <form onSubmit={handleReviewSubmit}>
            <TextField
              label="Your Review"
              variant="outlined"
              multiline
              fullWidth
              rows={2}
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              className={classes.reviewTextField}
              required

            />
            <Box display="flex" justifyContent="flex-end" className={classes.buttonbox}>
              <Button
                type="button"
                variant="contained"
                onClick={handleCancelReview}
                className={`${styleClasses.individualButton} ${expertClasses.button2}`}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={`${classes.button1}`}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      )}

      {/* Reviews List */}
      {/* <Box className={classes.reviewList}>
        {reviews.length > 0 ? (
          reviews.map((review, index) => (
            <Box key={index} className={classes.singleReview}>
              <Typography variant="subtitle2" color="textSecondary">
                {review.author} on {review.date}
              </Typography>
              <Typography variant="body1">{review.text}</Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            No reviews yet. Be the first to add a review!
          </Typography>
        )}
      </Box> */}

    </Box>
  );
};


const PriceCTACard = ({ styleClasses, expertClasses, price ,openFormModal}) => {

  const history = useHistory();
  

  return (
    <Box className={expertClasses.additionalCTACard}>
      <Typography variant="h4" className={styleClasses.h5heading} style={{ marginBottom: '16px' }}>
      Book A Discovery Call
      </Typography>
      <Typography variant="h6" className={styleClasses.h6banner}>
      {price ? `Starts From ${price}` : 'Contact us for pricing'}
      </Typography>
      <Typography variant="subtitle2" className={styleClasses.subtitle2font}>
      Book a 1:1 live video consultation
& get personalized advice
      </Typography>
      <Button
        variant="contained"
        className={`${styleClasses.individualButton} ${expertClasses.button2}`}
        onClick={openFormModal}
      // onClick={() => {
      //   // Define button action here
      //   history.push('/#'); // Adjust the path as needed
      // }}
      >
        Get Started
      </Button>
    </Box>
  );
};
// New CTA Card Component
const AdditionalCTACard = ({ styleClasses, expertClasses }) => {

  const history = useHistory();

  return (
    <Box className={expertClasses.additionalCTACard}>
      <Typography variant="h4" className={styleClasses.h5heading} style={{ marginBottom: '16px' }}>
        Unlock the power of high-impact content
      </Typography>
      <Typography variant="body1" className={styleClasses.body1font}>
        Boost your brand's visibility and engagement with our content marketing solutions
      </Typography>
      
    </Box>
  );
};

// Main ExpertProfile Component
const ExpertProfile = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const expertClasses = useExpertStyles();
  const theme = useTheme();
  const history = useHistory();
  const { profile_url } = useParams();
  const [formOpen, setFormOpen] = useState(false);

  // Find the expert based on profile_url
  const expert = useMemo(() => {
    return manualExperts.find((exp) => exp.profile_url === profile_url);
  }, [profile_url]);

  if (!expert) {
    return (
      <Box>
        {/* <Header /> */}
        <Container maxWidth="xl" style={{ marginTop: '32px' }}>
          <Typography variant="h4" color="textSecondary" align="center">
            Expert not found.
          </Typography>
        </Container>
        {/* <Footer /> */}
      </Box>
    );
  }

  // Helper function to determine image URL
  const getImageUrl = (img) => {
    // if (typeof img === 'string') {
    //   console.log("tis is image");
      
    //   // Check if img is an absolute URL
    //   if (/^https?:\/\//i.test(img)) {
    //     return img;
    //   } else {
    //     // Assume it's a relative path; require it from the assets
    //     try {
    //       return require(`../assets/Experts/${img}`).default;
    //     } catch (err) {
    //       console.error(`Image not found: ${img}`);
    //       return expert_profile; // Fallback image
    //     }
    //   }
    // } else {
    //   // If img is not a string, assume it's already a resolved URL/module
    //   return img;
    // }

    return img;
  };
  
  const openFormModal = () => {
    setFormOpen(true);
  };

  const closeFormModal = () => {
    setFormOpen(false);
  };


  return (
    <Box className={classes.resetOverflow}>
      {/* <Header /> */}
      <Container maxWidth="xl" className={expertClasses.container}>
        {/* Expert Header with Overlapping Avatar and Mobile Avatar */}
        <Box position="relative" marginBottom={theme.spacing(1)}>
          {/* Mobile Avatar Section */}
          <Box className={expertClasses.mobileAvatarSection}>
            <Avatar
              alt={expert.name}
              src={getImageUrl(expert.img)}
              className={expertClasses.expertAvatar}
              gutterBottom
            />
            {/* Request a Call Button for Mobile */}
            <Button
              onClick={openFormModal}
              className={styleClasses.individualButton}
            >
              Book a Discovery Call
            </Button>

          </Box>

          {/* Paper with Textual Information */}
          <Paper className={expertClasses.customPaper} elevation={3}>
            <Grid container alignItems="center">
              {/* Textual Information */}
              <Grid item xs={12} md={8} style={{ paddingLeft: 0 }}>
                <Box className={classes.headerText} style={{ marginTop: theme.spacing(0) }}>
                  <Typography variant="h2" gutterBottom className={`${styleClasses.h3font} ${classes.h2}`}>
                    {expert.name}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={`${styleClasses.h4font} ${classes.h4}`}>
                    {expert.industry}
                  </Typography>
                  <Typography variant="h5" gutterBottom className={`${styleClasses.h5heading} ${classes.h5}`}>
                    {expert.category}
                  </Typography>

                  <Typography variant="h6" gutterBottom className={`${styleClasses.description} ${styleClasses.h6HeadingDesc}`}>
                    {Array.isArray(expert.about) ? expert.about[0] : expert.about.substring(0, 125)}...
                  </Typography>
                  <Box className={`${styleClasses.filterChipsContainer} ${classes.chipContainer}`}>
                    {expert.skills?.map((skill, index) => (
                      <Chip
                        key={index}
                        label={skill}
                        className={styleClasses.filterChip}
                      />
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          {/* Desktop Avatar Section */}
          <Box className={classes.avatarSection}>
            <Avatar
              alt={expert.name}
              src={getImageUrl(expert.img)}
              className={expertClasses.expertAvatar}
              gutterBottom
            />
            {/* Request a Call Button for Desktop */}
            <Button
              onClick={openFormModal}
              className={`${styleClasses.individualButton} ${classes.individualButton}`}
            >
              Book a Discovery Call
            </Button>

          </Box>
        </Box>

        {/* About and Contact Sections */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <AboutSection about={expert.long_about || "No information available."}
              offers={expert.offers}
              classes={classes}
              styleClasses={styleClasses}
              expertClasses={expertClasses}
            />
            {/* Client Reviews Section */}
            <ClientReviewsSection
              classes={classes}
              styleClasses={styleClasses}
              expertClasses={expertClasses}
            />
          </Grid>

          <Grid item xs={12} md={4}>
          <PriceCTACard
              classes={classes}
              styleClasses={styleClasses}
              expertClasses={expertClasses}
              price = {expert?.price} 
              openFormModal={openFormModal}
            />


            {/* Existing CTA Card */}
            <Box className={expertClasses.ctaCard}>
              {/* Image Section */}
              <Box style={{ marginTop: 0 }}>
                <img
                  src={expert_profile}
                  alt="Supporting Business Growth Services"
                  style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  gutterBottom
                />
              </Box>
              <Typography variant="h5" className={styleClasses.h5heading}>
                Supporting Business Growth Services
              </Typography>
              <Typography variant="body1" className={styleClasses.body1font} gutterBottom>
                Access to a competitively priced talent pool of part-time associates that can support scaling and growth services
              </Typography>
              {/* Button Section */}
              <Button
        variant="contained"
        className={`${styleClasses.individualButton} ${expertClasses.button2}`}
        component = {Link}
        to= '/growth-selling-as-a-service'
      // onClick={() => {
      //   // Define button action here
      //   history.push('/#'); // Adjust the path as needed
      // }}
      >
        Get Started
      </Button>
             
            </Box>

            {/* Additional CTA Card */}
            {/* <AdditionalCTACard
              classes={classes}
              styleClasses={styleClasses}
              expertClasses={expertClasses} /> */}
          </Grid>
        </Grid>

        {/* Blog Section */}
        <TrendingBlogs
          blogs={blogData}
          title="Recommended blogs by our experts"
          subTitle=''
          showViewAllButton={false} // Hide the "View all blogs" button
        // Do not pass subTitle to omit the subtitle
        />

        <BookCallForm
          open={formOpen}
          onClose={closeFormModal}
          expertAvatar={getImageUrl(expert.img)}
          expertName={expert.name}
          expertIndustry={expert.industry}
        />
      </Container>
      {/* <Footer /> */}
    </Box>
  );
};

export default ExpertProfile;
// src/components/BrowseAllCourses.jsx

import React from 'react';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    Chip,
    Grid,
    Card,
    CardContent,
    Avatar,
    useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import useGlobalStyles from '../styles/CourseStyles';
import PropTypes from 'prop-types';

const BrowseAllCourses = ({ courses, context }) => {
    const classes = useGlobalStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const getHeading = () => {
        if (context === 'business') return 'Browse All Business Courses';
        if (context === 'individual') return 'Browse Our Courses';
        return 'Browse All Our Courses';
    };

    return (
        <Card className={classes.browseCoursesCard}>
            <Typography variant="h4" className={classes.h4font} align='center'>
                {getHeading()}
            </Typography>
            {/* Filter Chips */}

            {/* Course Cards */}
            <Grid container spacing={3} className={classes.courseGridContainer}>
                {courses.map((course) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={course.id}>
                        <Link
                            to={`/course/${encodeURIComponent(course.id)}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            <Card className={classes.courseCard}>
                                <Box
                                    className={classes.courseImage}
                                    style={{
                                        backgroundImage: `url(${course.imageUrl || 'https://academy.opengrowth.com/assets/images/courses/thumb_abc.jpeg'})`,
                                    }}
                                >
                                    <Typography variant="subtitle2" className={classes.overlayText}>
                                        {course.title}
                                    </Typography>
                                    <Chip label={course.category} size="small" className={classes.categoryChip} />
                                </Box>
                                <CardContent className={classes.courseContent}>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start',
                                            marginBottom: 8,
                                            textAlign: 'left'
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                variant="body2"
                                                className={classes.body2font}
                                            >
                                                {course.description}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                className={classes.body2font}
                                            >
                                                <CalendarTodayIcon style={{ fontSize: 14, marginRight: 4, marginTop: 7 }} />
                                                {course.duration}
                                            </Typography>
                                        </Box>
                                        {/* <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            {course.avatar.map((src, i) => (
                                                <Avatar
                                                    key={i}
                                                    src={src}
                                                    style={{ width: 35, height: 35, marginLeft: i === 0 ? 0 : -8 }}
                                                />
                                            ))}
                                        </Box> */}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Card>
    )
};

// Define PropTypes for type checking
BrowseAllCourses.propTypes = {
    courses: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        duration: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        imageUrl: PropTypes.string,
        avatar: PropTypes.arrayOf(PropTypes.string),
        ratings: PropTypes.number,
        reviews: PropTypes.number,
        views: PropTypes.number,
        comments: PropTypes.number,
    })).isRequired,
    context: PropTypes.oneOf(['business', 'individual']),
};

// Define default props
BrowseAllCourses.defaultProps = {
    context: null,
};

export default BrowseAllCourses;

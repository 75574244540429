// ExpertCard.jsx

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Typography,
  Chip,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab"; // Correct import for Rating in v4
import Skeleton from "@material-ui/lab/Skeleton"; // Import Skeleton from lab
import { Link } from "react-router-dom";
import useGlobalStyles from "../styles/CourseStyles";
import { PopupButton } from 'react-calendly';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  mainCard: {
    height: (props) => (props.context === "carousel" ? "20em" : "27em"),
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
  },
  cardMedia: {
    height: (props) => (props.context === "carousel" ? "100%" : "65%"),
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  nameBox: {
    position: "relative",
    top: (props) => (props.context === "allExperts" ? '210px' : props.context === "carousel" ? '240px' : 'auto'),
    width: '100%',
    height: '25%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0.1),
    paddingRight: theme.spacing(0.1),
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#fff',
  },
  cardContent: {
    padding: "16px",
    height: (props) => (props.context === "carousel" ? "auto" : "35%"),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    marginBottom: theme.spacing(1),
    fontSize: '0.75em',
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: 'none',
  },
  discoveryButton: {
    borderRadius: 20,
    fontSize: '1rem',
    padding: '8px 16px',
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {

      fontSize: '0.875rem'
    },
  },
  interactionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  chipsBox: {
    display: "flex",
    gap: '8px',
    flexWrap: "wrap",
  },
}));

const ExpertCard = ({ expert, handleExpertClick, calendlyUrl, context, loading, role }) => {
  const classes = useStyles({ context });
  const styleClasses = useGlobalStyles({ context });
  const theme = useTheme();

  // Helper function to check if a URL is absolute
  const isAbsoluteURL = (url) => /^https?:\/\//i.test(url) || /^\//.test(url);

  // Guard for empty `expert` prop to prevent rendering empty components
  if (!expert && !loading) {
    return null;
  }

  // Loading Skeleton
  if (loading) {
    return (
      <Box className={classes.mainCard}>
        <CardMedia className={classes.cardMedia}>
          <Skeleton
            variant="rect"
            width="100%"
            height="100%"
            animation="wave"
          />
        </CardMedia>
        {context !== "carousel" && (
          <CardContent className={classes.cardContent}>
            <Skeleton variant="text" height={30} width="60%" animation="wave" />
            <Skeleton variant="text" height={20} width="40%" animation="wave" style={{ marginTop: '8px' }} />
            <Skeleton variant="text" height={20} width="80%" animation="wave" style={{ marginTop: '16px' }} />
            <Skeleton variant="rect" height={30} width="40%" animation="wave" style={{ marginTop: '16px' }} />
            <Skeleton variant="rect" height={20} width="30%" animation="wave" style={{ marginTop: '16px' }} />
          </CardContent>
        )}
      </Box>
    );
  }

  // Determine the correct image URL
  // const imageUrl = isAbsoluteURL(expert.img)
  //   ? expert.img
  //   : `https://academy.opengrowth.com/assets/images/users/${expert.img}`;

  const imageUrl = expert.img;


  return (
    <Box className={classes.mainCard}>
      <CardMedia
        className={classes.cardMedia}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <Link
          to={
            context === "expertPage"
              ? `/profile/${expert?.profile_url}`
              : context === "myConnection"
                ? `/detail/${expert?.profile_url}`
                : `/expert-profile/${expert?.profile_url}`
          }
          style={{ textDecoration: "none", display: 'flex', height: '100%' }}
          state={{ expertEmail: expert?.email }}
        >
          {expert?.name && (
            <Box className={classes.nameBox}>
              <Typography variant="subtitle1" align="center" className={styleClasses.subtitlefont}>
                {expert.name}
              </Typography>
              {/* Display category instead of industry */}
              <Typography
                variant="subtitle2"
                align="center"
                className={styleClasses.subtitle2font}
              >
                {expert.category}
              </Typography>
            </Box>
          )}
        </Link>
      </CardMedia>

      {context !== "carousel" && expert && (
        <CardContent className={classes.cardContent}>
          <Typography
            variant="body2"
            className={styleClasses.body2font}
          >
            {expert?.about}
          </Typography>

          {context === "allExperts" ? (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <PopupButton
                url={calendlyUrl}
                rootElement={document.getElementById('root') || undefined}
                text="Book a Discovery Call"
                className={`${classes.discoveryButton} ${styleClasses.businessButton}`}
                variant="contained"
              />
            </Box>
          ) : context === "myConnection" ? (
            <Box className={classes.interactionsBox}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontWeight: "bold" }}
              >
                Interactions: 9
              </Typography>
              <Rating name="read-only" value={3.5} readOnly />
            </Box>
          ) : (
            <Box style={{ marginTop: theme.spacing(1) }}>
              <Box className={classes.chipsBox}>
                {/* If role is provided, display it as a Chip; else, display categories and industry */}
                {role ? (
                  <Chip
                    label={role}
                    variant="outlined"
                    color={
                      role === "AI Expert"
                        ? "primary"
                        : role === "Growth Expert"
                          ? "secondary"
                          : "default"
                    }
                    size="small"
                  />
                ) : (
                  <>
                    {expert.category &&
                      expert.category.split(",").map((cat, index) => (
                        <Chip
                          key={index}
                          label={cat.trim()}
                          variant="outlined"
                          color="primary"
                          size="small"
                        />
                      ))}
                    {expert.industry && (
                      <Chip
                        label={expert.industry}
                        variant="outlined"
                        color="secondary"
                        size="small"
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          )}
        </CardContent>
      )}
    </Box>
  );
};

export default ExpertCard;

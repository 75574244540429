import React from 'react';
import {makeStyles,Box,Button,Dialog,DialogTitle,DialogContent,DialogActions,Typography,IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles(theme => ({
	border:{'border-radius':'12px'},
	height:{'line-height':'1.25rem'},
	title:{fontWeight:'700'},
	li:{color: 'rgb(90, 105, 120)',fontSize: '14px',
	'font-family': 'IBM Plex Sans,-apple-system,BlinkMacSystemFont,sans-serif',
	'line-height':'1.25rem',
    'margin-bottom': '8px'},
	icon:{fontSize:"0.85rem",},
	iconsbg:{backgroundColor:'#0000001f',padding:'8px'}
}))
const PolicyDialog = ({ open, parentCallback }) => {
	const classes = useStyles();
  const handleClose = () => {
    parentCallback(false);
  };
	const DialogHeader = ({title}) => {
    return (
	<Box width="100%" display="flex" flexDirection="row">
				
				<Box flexGrow={1} >
				<Typography  variant="h6"  color="textPrimary" className={classes.title}>
					{title}
				</Typography>
				</Box>
				<IconButton 
				onClick={handleClose}
				className={classes.iconsbg}>
				<CloseIcon color="grey"  className={classes.icon} />
				</IconButton>
	</Box>
	)}
  return (
   
	<Dialog
			 open={true} onClose={handleClose} 
			fullWidth className={classes.border}
			maxWidth="lg">	 
			<DialogTitle  onClose={handleClose} >
			<DialogHeader title="Privacy Policy" />
			</DialogTitle>
			<DialogContent dividers>
			 <Box padding={2} >
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>
						By accessing our site, you agree to the following:
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>
						Thank you for choosing OpenGrowth. We value your trust. One of our main priorities is the
						privacy of
						our visitors, clients, partners, and students. This document contains information on what data
						is
						collected and recorded by OpenGrowth and how we use it.</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>We adhere to ethical standards in gathering, using and safeguarding any information that you
						submit
						to us while opting for a course or subscribing to our services or during employment or
						incorporation
						of business or collaboration of any kind.</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>The policy is not applicable for the data collected offline or through any physical source from
						our
						users or any other channel or a third party other than the website. If you have any queries,
						please
						feel free to contact us at contact@opengrowth.com</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>By using our website, you hereby consent to our Privacy Policy and agree to its terms of service.

					</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>
						Introduction
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>


						The OpenGrowth Privacy Policy applies to all users, students, clients, and visitors who access
						the
						OpenGrowth website through the internet using computers, mobile devices, laptops, or other
						electronic devices. The terms 'we,' 'us,' and 'our' refer to OpenGrowth, and the policy
						specifies
						the types of personally identifiable or personal information that may be collected, as well as
						how
						much information is used and the choices that can be made. Any individual major or minor who has
						visited OpenGrowth or subscribed to any of its mentorship/services/courses/collaboration
						tools/webinars is referred to as a "you" or "user."


					</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>
						Information we collect
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>

						some sections of our site can be accessed without revealing personal information. But, for
						getting
						access to features or services related to mentorship, courses, content offerings or webinars or
						any
						other startup services or access to the OpenGrowth Hub platform. To get this access you have to
						submit your personal information. Here, in ‘Personal Information’ includes information that can
						be
						used to identify you as a person. It may also be referred to as personally identifiable
						information
						or personal information. Personal data/information may include your name, email address, IP
						address,
						and device identifier along with any other information as and when required. You are solely
						responsible for ensuring that the information that you have shared with us is completely
						authentic
						and relevant. Incorrect information may affect your accessibility to our services.



					</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>
						By accessing our site, you agree to the following:
					</Typography>
					<ul>
						<li className={classes.li}>We need to collect your personal information because your personal information is needed for
							us
							to give you access to certain services. While using the website we are bound to agree to all
							the
							terms and conditions, along with the conditions mentioned in the Privacy Policy. By
							accepting
							them you comply with the contract for the services we provide to you, in complete accordance
							with our policies and terms of service. </li>
						<li className={classes.li}>We need to comply with our legal obligations for which we need your personal information.
						</li>
						<li className={classes.li}>Also, personal information is needed for the legitimate interests of us and others. Our
							legitimate interests include:
							<ul>
								<li className={classes.li}>Visit and use our Website</li>
								<li className={classes.li}>Avail to our services</li>
								<li className={classes.li}>Select appropriately skilled and qualified suppliers </li>
								<li className={classes.li}>Build relationships with our audience, clients, partners, and other academic
									institutions</li>
								<li className={classes.li}>For research and statistical purposes</li>
								<li className={classes.li}>Marketing and business development purposes </li>
								<li className={classes.li}>Internal administrative and auditing purposes.</li>
							</ul>
						</li>

					</ul>


					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>
						Which information can be used for which legal activity will depend on the personal information
						that
						you share with us. Also, in case of any legal issues, the jurisdiction shall be any court of law
						in
						India only. </Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>It is our responsibility to protect your information when we collect it. Our organization does
						not
						tolerate misuse of personal information by any representative of OpenGrowth or any other firm,
						individual or group of individuals associated with OpenGrowth.</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>While collecting personal information we also undertake a balancing test that our legitimate
						interests are not weighed and vice versa.</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>By agreeing to our terms of service, and privacy policy, you also give your consent to use
						personal
						information for marketing purposes. However, if you do not want us to use it for information,
						you
						can write to us at contact@opengrowth.com

					</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>What information we collect and how</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>While availing to the services provided by OpenGrowth, you share your personal information. You
						can
						write to us at contact@opengrowth.com if you need to change your personal information shared
						with us
						while using our website.</Typography>

					<ul>
						<li className={classes.li}>
							While using our website, we may track, collect and aggregate information indicating other
							things
							including the pages you visited while browsing our site, the order in which they were
							visited,
							the time - when they were visited, which hyper links were clicked while browsing our site.
						</li>
						<li className={classes.li}>We also collect information from which other website you were directed to our website. In
							this
							process, we may also collect information which may include logging into your IP address,
							operating systems and browser software used for our site. </li>
						<li className={classes.li}>IP address is needed for us to locate your geographical information and your internet
							service
							provider. Also, location data may be tracked for your improved experience.</li>
						<li className={classes.li}>We collect personal information when you log in to our website or register with us for
							creating
							an account, update or change information of your already made account, make subscriptions,
							sign
							up for email updates, newsletters, participate in our events, public forums, communicate
							through
							us through emails and/or participate in any content offerings for our website. </li>
						<li className={classes.li}>Your personal information may also be collected when you use Content Offering services which
							include participation in video quizzes, standalone quizzes, examinations and online surveys.
						</li>
						<li className={classes.li}>You are not allowed to use your any other personal information (excluding from what is
							required/asked for) on the website. Also, personal or sensitive data of other individuals,
							groups/groups of individuals, firms, and organizations also should not be shared or
							published on
							the website or mentioned in your answer sheets or assignments or surveys. </li>
						<li className={classes.li}>While availing to a few services, you are also required to share your personal information
							such
							as name, address, contact details, email address, photograph, access to webcam, and photo
							identification among many others, with a third party site, related to us. </li>
						<li className={classes.li}>Many services offered by OpenGrowth are paid services. This payment may be processed through
							a
							third-party payment gateway through online payment methods, debit cards, or credit cards.
						</li>

					</ul>

					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom><span>Note:</span> A third-party service provider may collect and process your financial
						information
						along with OpenGrowth. </Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>Use of your information</Typography>

					<ul>
						<li className={classes.li}>OpenGrowth takes all the necessary precautions to not leak the personal information shared
							with us
							without your consent. If you need to make any changes in the information, OpenGrowth allows
							you to
							make changes and then render our services available to you. Before modification of your
							personal
							information, OpenGrowth may ask for your photo identification and then act on your request.
						</li>
						<li className={classes.li}>OpenGrowth has the right to deny any request that is contrary to the law. </li>
						<li className={classes.li}>We do not guarantee that the residual copies and archives will be deleted once your services
							have
							been availed, though avoid retaining your personal information for long. </li>
						<li className={classes.li}>Your personal information will be shared with third-party service providers only with your
							consent,
							be it for marketing purposes of financial transactions. </li>
						<li className={classes.li}>OpenGrowth will use personal information with respect to the platform. </li>
						<li className={classes.li}>OpenGrowth can communicate with you through calls, emails, messages and any other medium of
							digital
							communication as and when required. </li>
						<li className={classes.li}>It is at the user's discretion to stop the communication, by unsubscribing to our
							communication
							services. </li>
						<li className={classes.li}>For any other queries feel free to contact us on contact@opengrowth.com
						</li>
					</ul>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>Confidentiality, Report and Analysis</Typography>
					<ul>
						<li className={classes.li}>OpenGrowth may use your personal information for marketing purposes, and reference purposes,
							and
							improve our services and business intelligence for research and statistical or intelligent
							data.
						</li>
						<li className={classes.li}>All employees working with OpenGrowth associated with collecting, and processing will have
							access to
							your data. They are obliged to respect the confidentiality of the data. </li>
						<li className={classes.li}>When you log in, our servers automatically record the information that you provide us or
							your
							browser sends us, whenever you visit our website</li>

					</ul>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>Advertisements &amp; Cookie Policy </Typography>
					<ul>
						<li className={classes.li}>OpenGrowth may display interest-based advertising using the information you make available
							to us
							while interacting with us. </li>
						<li className={classes.li}>We follow the Indian laws while displaying interest-based ads to you and the self-code
							regulation in
							advertising by the Advertising Standards Council of India and Self Regulatory Principles for
							Online
							Behavioral Advertising developed by the Digital Advertising Alliance. </li>
						<li className={classes.li}>Like other online ad networks, we use cookies, web beacons (also known as action tags or
							single-pixel gifs), and other technologies (collectively, “cookies”). Cookies enable us to
							learn
							about what ads You see, what ads You click, and other actions You take on our sites and
							other sites.
							This allows us to provide You with more useful and relevant ads. For example, if we know
							what ads
							You are shown we can be careful not to show You the same ones repeatedly. We do not
							associate Your
							interaction with unaffiliated sites with Your identity in providing You with interest-based
							ads.
						</li>
						<li className={classes.li}>We do not provide any personal information to advertisers or third-party users. However, the
							advertisers may get your IP address through technology. For any such query, please contact
							them
							directly. OpenGrowth is not responsible for such access to information. </li>

					</ul>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>Third-Party cookies </Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>We also use third-party cookies other than our own cookies for marketing purposes. </Typography>


					<ul>
						<li className={classes.li}>Tracking cookies follow on-site behavior allowing a better understanding of the user's
							habits. </li>
						<li className={classes.li}>Optimization cookies allow real-time tracking of user conversions from different marketing
							platforms. </li>
						<li className={classes.li}>Partner cookies Provide marketing conversion metrics to our partners so that they can
							optimize paid
							marketing efforts. </li>

					</ul>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>Cookies you can choose</Typography>

					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>
						While using our website, you have the right to delete cookies or instruct your web browser to
						delete or
						refuse cookies if you do not want them. You can do that by visiting the help pages of your web
						browser.
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom><span>Note: </span> If you delete cookies or refuse to use them, you will not be able to access
						some of
						the available features on the website. Like, you might not be able to log in or make us remember
						your
						preferences.

					</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>Refund Policy</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>Once enrolled for any course or taken up a mentorship program or a subscription, no refund shall
						be made
						to the candidate, student, individual or a company if they fail to avail the services on time,
						within a
						specified time, or lapses their subscription period, or any other reason.
					</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>Revision of terms &amp; Grievance reporting</Typography>

					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>OpenGrowth may update or amend this Policy at any time, with or without advance notice. In the
						event
						there are significant changes in the way OpenGrowth treats Users' personally identifiable
						information,
						OpenGrowth will display a notice on the Platform or send Users an email. OpenGrowth's current
						Policy to
						all information that OpenGrowth has about Users and their accounts.</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>Notwithstanding the above, OpenGrowth shall not be required to notify the Users of any changes
						made to
						the privacy policy. Should You have any concerns or reject the changes in the privacy policy You
						can
						refuse to accept the amendments and opt for withdrawing Your Personal Information by writing to
						us
						contact@opengrowth.com.</Typography>

				</Box>
			</Box>
			</DialogContent>
		  </Dialog>
			
  );
};

export default PolicyDialog;

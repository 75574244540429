// Page2.js
import React, { useState, useEffect } from "react";
import { Typography, Box, Checkbox, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import useGlobalStyles from "../../../../opengrowth/styles/CourseStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(6),
    margin: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(1.5),
    fontWeight: 500,
    color: '#303030',
  },
  subtitle: {
    color: '#616161',
  },
  section: {
    marginTop: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
  list: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '1fr',
    overflowY: 'auto',
    listStyle: 'none',
    padding: 0,
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  listItem: {
    border: '0.5px solid #e0e0e0',
    borderRadius: '0.375rem',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  listItemSelected: {
    backgroundColor: '#f7f7f7',
  },
  checkbox: {
    marginRight: theme.spacing(2),
  },
  // New styles for buttons
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  rightButtons: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

const platforms = [
  { name: "Leadership", imageLink: null },
  { name: "Product" },
  { name: "Marketing" },
  { name: "Strategy", imageLink: null },
];

const experts = [
  { name: "Demand Engagement", imageLink: null },
  { name: "Artificial Intelligence" },
  { name: "Human Resource" },
  { name: "Entrepreneurship", imageLink: null },
];

function Page2({ data = {}, onDataChange, reset, onNext, onSkip, onSkipAll, onBack, showBackButton }) {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const [selectedCourses, setSelectedCourses] = useState(data.courses || []);
  const [selectedExperts, setSelectedExperts] = useState(data.experts || []);

  // Handler for selecting/deselecting a course by name
  const handleSelectCourse = (name) => {
    let newSelected;
    if (selectedCourses.includes(name)) {
      newSelected = selectedCourses.filter(course => course !== name);
    } else {
      newSelected = [...selectedCourses, name];
    }
    setSelectedCourses(newSelected);
    onDataChange({ courses: newSelected, experts: selectedExperts });
  };

  // Handler for selecting/deselecting an expert by name
  const handleSelectExpert = (name) => {
    let newSelected;
    if (selectedExperts.includes(name)) {
      newSelected = selectedExperts.filter(expert => expert !== name);
    } else {
      newSelected = [...selectedExperts, name];
    }
    setSelectedExperts(newSelected);
    onDataChange({ courses: selectedCourses, experts: newSelected });
  };

  // Reset selections when `reset` prop changes to true
  useEffect(() => {
    if (reset) {
      setSelectedCourses([]); // Reset courses
      setSelectedExperts([]); // Reset experts
      onDataChange({ courses: [], experts: [] }); // Notify parent of reset
    }
  }, [reset, onDataChange]);

  // Update selections when `data` prop changes
  useEffect(() => {
    setSelectedCourses(data.courses || []);
    setSelectedExperts(data.experts || []);
  }, [data]);

  return (
    <Box className={classes.root}>
      <Typography variant="h5" component="h1" className={`${classes.title} ${styleClasses.h5fontPara}`} gutterBottom>
        I'm interested in expert/course categories
      </Typography>
      <Typography variant="body2" className={`${classes.subtitle} ${styleClasses.body1font}`} align="center">
        Please select the courses and experts you are interested in.
      </Typography>

      {/* Courses Section */}
      <Box className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Courses
        </Typography>
        <Box
          component="ul"
          className={classes.list}
        >
          {platforms.map((platform) => (
            <Box
              component="li"
              key={platform.name}
              onClick={() => handleSelectCourse(platform.name)}
              className={`${classes.listItem} ${selectedCourses.includes(platform.name) ? classes.listItemSelected : ''}`}
            >
              <Checkbox
                checked={selectedCourses.includes(platform.name)}
                onChange={() => handleSelectCourse(platform.name)}
                className={classes.checkbox}
              />
              <span>{platform.name}</span>
              {platform.imageLink && (
                <img
                  src={platform.imageLink}
                  alt={platform.name}
                  style={{ marginLeft: 'auto', height: '30px' }}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>

      {/* Experts Section */}
      <Box className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Experts
        </Typography>
        <Box
          component="ul"
          className={classes.list}
        >
          {experts.map((expert) => (
            <Box
              component="li"
              key={expert.name}
              onClick={() => handleSelectExpert(expert.name)}
              className={`${classes.listItem} ${selectedExperts.includes(expert.name) ? classes.listItemSelected : ''}`}
            >
              <Checkbox
                checked={selectedExperts.includes(expert.name)}
                onChange={() => handleSelectExpert(expert.name)}
                className={classes.checkbox}
              />
              <span>{expert.name}</span>
              {expert.imageLink && (
                <img
                  src={expert.imageLink}
                  alt={expert.name}
                  style={{ marginLeft: 'auto', height: '30px' }}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>

      {/* Buttons Section */}
      <Box className={classes.buttonContainer}>
        {showBackButton && (
          <Button variant="text" onClick={onBack}>
            Back
          </Button>
        )}
        <Box className={classes.rightButtons}>
          <Button variant="text" onClick={onSkip}>
            Skip
          </Button>
          <Button variant="text" onClick={onSkipAll}>
            Skip All
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onNext}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Page2;

// src/components/FeaturesSection.jsx

import React from 'react';
import {
    Box,
    Typography,
    Card,
    Button,
    Grid,
    CardContent,
    CardMedia,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useGlobalStyles from '../styles/CourseStyles'; // Ensure this path is correct
import featureImage from '../assets/home_banner2.png'; // Replace with your actual image path or use a placeholder
import { BsAlignBottom } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    // Styles for FeaturesSection
    featuresSectionContainer: {
        borderRadius: '8px',
        marginTop: theme.spacing(6),
        padding: theme.spacing(4),
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    sectionHeading: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        color: '#25387c', // Dark blue color
    },
    subHeading: {
        fontWeight: '600',
        marginBottom: theme.spacing(1),
        color: '#25387c',
    },
    description: {
        marginBottom: theme.spacing(2),
        color: '#555', // Darker gray for readability
    },
    benefitsList: {
        listStyleType: 'disc',
        paddingLeft: theme.spacing(3),
        '& li': {
            marginBottom: theme.spacing(1),
        },
    },
    benefitItem: {
        color: '#555',
    },
    featureCard: {
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '40%',
        height: '100%', // Make the card fill the height of its container
        
    },
    featureCardMedia: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    featureCardTitle: {
        fontWeight: '600',
        color: '#25387c',
        marginBottom: theme.spacing(1),
    },
    featureCardDescription: {

        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    getStartedButton: {
        backgroundColor: '#f5f5f5',
        color: '#000',
        borderRadius: '50px',
        textTransform: 'none',
        padding: '8px 16px',
        marginTop: '8px',
        '&:hover': {
            backgroundColor: '#f9bb02',
            transform: 'translateY(-2px)',
        },
    },
    h5: {
        marginBottom: theme.spacing(1),
    },
    h6font: {
        color: '#25387c'
    },
    leftColumnBox: {
        padding: '0 0px 8px 0px',
        marginBottom: '1.5rem', // Add spacing between boxes
    },
    leftGrid: {
        display: 'flex',
        flexDirection: 'column',
        padding: '32px !important',
        paddingLeft: '16px !important',
        justifyContent: 'center',
    }
}));

const FeaturesSection = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();

    return (
        <Box className={classes.featuresSectionContainer}>
            {/* Section Heading */}

            <Typography
                variant="h6"
                className={`${styleClasses.description} ${classes.h6font}`}
                gutterBottom
            >
                Supercharge your startup with expert-led, targeted lead generation and data enrichment. Our
                experienced Growth Associates specialize in dynamic business development, offering:
            </Typography>

            {/* Two-Column Layout */}
            <Grid container spacing={4} alignItems="stretch"> {/* Ensure both columns stretch to the same height */}
                {/* Left Column - 60% */}
                <Grid item xs={12} md={8} className={classes.leftGrid}>
                    <Box className={classes.leftColumnBox}>
                        <Typography variant="h5" className={`${styleClasses.h5font} ${classes.h5}`}>
                        Strategic Marketing & Brand Management
                        </Typography>
                        <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}    >
                        Boost your brand with associates who craft effective marketing
                        strategies and manage your brand for lasting growth.
                        </Typography>
                    </Box>
                    <Box className={classes.leftColumnBox}>
                        <Typography variant="h5" className={`${styleClasses.h5font} ${classes.h5}`}>
                            Data Enrichment & Targeted Lead Generation
                        </Typography>
                        <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}>
                            Our associates refine data and source high-value leads to
                            connect you with the right audience and drive sales.
                        </Typography>
                    </Box>
                    <Box className={classes.leftColumnBox}>
                        <Typography variant="h5" className={`${styleClasses.h5font} ${classes.h5}`}>
                            Payroll Management
                        </Typography>
                        <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}>
                            Ensure accurate payroll processing and compliance with expert
                            associates, freeing you to focus on business growth.
                        </Typography>
                    </Box>
                    <Box className={classes.leftColumnBox}>
                        <Typography variant="h5" className={`${styleClasses.h5font} ${classes.h5}`}>
                            Recruitment & Onboarding
                        </Typography>
                        <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}>
                            Hire top talent quickly with associates who handle recruitment
                            and onboarding for seamless team integration.
                        </Typography>
                    </Box>
                </Grid>

                {/* Right Column - 40% */}
                <Grid item xs={12} md={4}>
                    <Card className={classes.featureCard}>
                        <CardMedia
                            className={classes.featureCardMedia}
                            image={featureImage} // Replace with your desired image
                            title="Growth Expert"
                        />
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FeaturesSection;

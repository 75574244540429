import React from 'react';
import {makeStyles,Box,Button,Dialog,DialogTitle,DialogContent,DialogActions,Typography,IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles(theme => ({
	border:{'border-radius':'12px'},
	height:{'line-height':'1.25rem'},
	title:{fontWeight:'700'},
	li:{color: 'rgb(90, 105, 120)',fontSize: '14px',
	'font-family': 'IBM Plex Sans,-apple-system,BlinkMacSystemFont,sans-serif'},
	icon:{fontSize:"0.85rem"},
	iconsbg:{backgroundColor:'#0000001f',padding:'8px'}
}))

const TermsDialog = ({ open, parentCallback }) => {
	const classes = useStyles();
  const handleClose = () => {
    parentCallback(false);
  };
	const DialogHeader = ({title}) => {
    return (
	<Box width="100%" display="flex" flexDirection="row">
				<Box flexGrow={1} >
				<Typography  variant="h6"  color="textPrimary" className={classes.title}>
					{title}
				</Typography>
				</Box>
				<IconButton 
				onClick={handleClose}
				className={classes.iconsbg}>
				<CloseIcon color="grey"  className={classes.icon} />
				</IconButton>
	</Box>
	)}
  return (
   
	<Dialog
			 open={true} onClose={handleClose} 
			fullWidth className={classes.border}
			maxWidth="lg">	 
			<DialogTitle  onClose={handleClose} >
			<DialogHeader title="Terms & Conditions"  />
			</DialogTitle>
			   <DialogContent dividers>
			 
			  <Box padding={2} >
				<Box marginBottom={2}  >
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>
						Welcome to OpenGrowth, we work better together.  By using the services provided by us, you agree to abide by the terms of our service. 


					</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>
						Introduction

					</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>This user agreement (which can be also stated as Terms &amp; Conditions, T&amp;C, Terms of Agreement, Terms of Service) is between you ‘User’ (can also be referred as customer, registered user, buyer, end user)and OpenGrowth ‘service provider’(can be also referred as Company, us, we, Our or OpenGrowth).  These terms and conditions are applicable when you are using OpenGrowth through the internet, mobile phones, digital television or any other form of electronic communication. </Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>These terms &amp; conditions do not require any physical or digital signature. If there is a situation of conflict between the terms mentioned in this agreement or any other document, the terms &amp; conditions mentioned in this agreement shall alone prevail for the use of services provided by OpenGrowth.</Typography>
					
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>
						
Right to Change 


					</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>
						OpenGrowth has the sole right to update, upgrade, modify or change these terms &amp; conditions at any point without any prior notice to the user. As a result, we recommend that you examine these terms and conditions on a regular basis to avoid any inconvenience.
					</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>
					
General Communications 


					</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>

						When you use any of OpenGrowth's services or send us emails, you are communicating with us electronically. You consent to receive messages from us in the form of emails, newsletters, alerts, notices, or any other information as and when required through emails, pop-ups, notices, or any other form of electronic services by agreeing to these terms and conditions. You also agree that any notices, e-mails, agreements, or disclosures we provide you electronically can be utilized as a legal document or evidence in the event of a disagreement between you and us.

					</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>
						
Intellectual property Rights and Content Ownership

					</Typography>
					
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>OpenGrowth owns all the intellectual Property Rights to and into the website, mobile application, and any other form, unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party and expressly mentioned on the site, without any limitation, any and all rights which also includes title and interest in and to copyright, related rights, patents, models, utility models, trademarks, names, trade names, service marks, designs, know-how, trade secrets and any inventions (whether made patent or not), goodwill, source code, meta tags, databases, text, content in any form, pictures, videos, graphics and hyperlinks.</Typography> 
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>You acknowledge that without a proper authorization letter or previous notification, you will not use, reproduce, or distribute any content from OpenGrowth while accepting the terms and conditions.</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>Also, without sufficient clearance or prior consent from any signatory authority from OpenGrowth, you are not authorized to post, upload, or copy-paste any material available on OpenGrowth that is protected by copyright, trademark, or other intellectual rights. We shall not be liable to the user to provide with any indications or marking stating that above-mentioned content in any form are copyrighted or has a patent to it.  </Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>User shall be solely liable for any damage resulting from any infringement of Copyrights, trademarks, proprietary rights or any other harm resulting from such a submission. By submitting material to any public area of the Site, User warrants that the owner of such material has expressly granted OpenGrowth the royalty-free, perpetual, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate and distribute such material (in whole or in part) worldwide and/or to incorporate it in other works in any form, media or technology now known or hereafter developed for the full term of any copyright that may exist in such material. </Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>User also permits any other end-user to access, view, and store or reproduce the material for that end user’s personal use. The User hereby grants OpenGrowth the right to edit, copy, publish and distribute any material made available on the Site by the User.</Typography>
					

			
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>
						Breach in complying with the Terms &amp; Conditions 

						</Typography>
					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>						If under any circumstances, you breach the terms of this agreement or the Privacy policy, and may cause legal liability for you, other users or us, OpenGrowth has the right to immediately terminate/ limit your access/activity on the website on any device as mentioned above. OpenGrowth also has the right to remove your information, temporarily/indefinitely suspend or terminate or block your membership, and/or refuse to provide you with access to this Website. Any user that has been suspended or blocked may not register or attempt to register with us or use the Website in any manner whatsoever until such time that such user is reinstated by us.</Typography>
						<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>Notwithstanding the foregoing, if you breach the Terms of Use or Privacy Policy or other rules and policies, we reserve the right to recover any amounts due and owing by you to us and to take strict legal action including but not limited to a referral to the appropriate police or other authorities for initiating criminal or other proceedings against you.</Typography>
						<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>Any breach of any applicable local laws of that territory shall also result in, without prior notice, immediately limiting your activity, removing your information, temporarily/indefinitely suspending or terminating or blocking your membership, and/or refusing to provide you with access to this OpenGrowth.
						</Typography>

				

				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>
						Severability 
						
</Typography>

					<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>At any time, OpenGrowth maintains the right to make changes to our site, policies, and terms and conditions. If any of these conditions is found to be invalid, void, or unenforceable for any reason, that condition will be deemed severable, and the legality and enforceability of the other conditions will not be affected.</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>						
						Waiver
						
						
</Typography>
			<Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>The failure by OpenGrowth to enforce at any time or for any period any one or more of the terms or conditions of the Agreement shall not be a waiver by OpenGrowth of them or of the right any time subsequent to enforce all Terms and Conditions of this agreement.</Typography>
				</Box>
				<Box marginBottom={2}  >
					<Typography variant="subtitle1" color="textPrimary" className={classes.title} gutterBottom>						Contact Us
</Typography><Typography variant="subtitle2" color="textSecondary" className={classes.height} gutterBottom>						Please contact us at contact@opengrowth.com if you have any grievances about using the website.</Typography>
					
				</Box>
				
			</Box>
			</DialogContent>
		  </Dialog>
			
  );
};

export default TermsDialog;

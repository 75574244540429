// src/components/Testimonials.js

import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Avatar,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useGlobalStyles from '../styles/CourseStyles';

// Sample testimonials data
const testimonialsData = [
  {
    name: "Shradha Verma",
    content:
      "OpenGrowth Hub boosts our team's productivity and management, streamlining essential tasks and saving time.",
    image:
      "https://www.opengrowth.com/assets/public/opengrowth/images/testimonial/shradha-verma-testimonial.jpg",
  },
  {
    name: "Shellye",
    content:
      "Working with OpenGrowth’s team on my website has been a pleasure. Their work ethic and quality impress me. I look forward to our continued collaboration.",
    image:
      "https://www.opengrowth.com/assets/public/opengrowth/images/testimonial/shellye-testimonial.png",
  },
  {
    name: "Negin",
    content:
      "OpenGrowth Hub has streamlined task organization, collaboration, progress tracking, and project assignment, elevating our business.",
    image:
      "https://www.opengrowth.com/assets/public/opengrowth/images/testimonial/negin.jpg",
  },
  {
    name: "Amit Kumar",
    content:
      "An OpenGrowth expert boosted our performance and efficiency, streamlining operations and driving growth. I'm grateful for their support and future collaboration.",
    image:
      "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
];

// Styles using makeStyles
const useStyles = makeStyles((theme) => ({
  cardRoot: {
    padding: theme.spacing(3),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: 'none',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subtitle: {},
  testimonialCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    borderRadius: '8px',
    border: 'none',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#fff', // Ensure background is white for better readability
  },
  avatar: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginBottom: theme.spacing(1),
  },
  testimonialName: {
    fontWeight: 'bold',
    fontFamily: 'MyCustomFont, sans-serif',
    color: '#2c3e50',
  },
  
  truncated: {
    display: '-webkit-box',
    WebkitLineClamp: 2, // Limit to 2 lines
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  expanded: {
    whiteSpace: 'normal',
  },
  testimonialSmall: {
    paddingTop: '32px',
  },
  h6font: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

// TestimonialCard component
const TestimonialCard = ({ name, content, image, classes, styleClasses }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleContent = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Card className={classes.testimonialCard}>
      <Avatar src={image} alt={name} className={classes.avatar} />
      <Typography variant="h6" className={classes.testimonialName}>
        {name}
      </Typography>
      <Typography
        variant="body1"
        className={`${
          isExpanded ? classes.expanded : classes.truncated
        } ${styleClasses.body1font}`}
      >
        {content}
      </Typography>
      {/* Show "Read More" or "Read Less" based on state */}
      <Button
        size="small"
        className={styleClasses.readMoreButton}
        onClick={toggleContent}
      >
        {isExpanded ? 'Read Less' : 'Read More'}
      </Button>
    </Card>
  );
};

// Testimonials component
const Testimonials = ({ context }) => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // Define contexts that should use the current subtitle
  const courseContexts = ['allcourses', 'businesscourse', 'individualcourse'];

  // Determine if the provided context is one of the course contexts
  const isCourseContext = courseContexts.includes(context?.toLowerCase());

  // Set subtitle based on context
  const subtitle = isCourseContext
    ? 'Join the ranks of professionals and businesses who have transformed their skills and achieved remarkable success with our courses. Hear from those who’ve experienced real results and take the next step in your journey.'
    : 'Learn how startups use OpenGrowth as a perfect partner for their growth journey';

  return (
    <Card className={classes.cardRoot}>
      <Typography
        variant="h4"
        className={`${classes.title} ${styleClasses.h4font}`}
        gutterBottom
      >
        Testimonials
      </Typography>
      <Typography
        variant="h6"
        className={`${styleClasses.description} ${classes.h6font}`}
        gutterBottom
        align="center"
      >
        {subtitle}
      </Typography>
      <Grid
        container
        spacing={isSmall ? 1 : 2}
        justifyContent="center"
        alignItems="stretch"
        className={classes.testimonialSmall}
      >
        {testimonialsData.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <TestimonialCard
              name={testimonial.name}
              content={testimonial.content}
              image={testimonial.image}
              classes={classes}
              styleClasses={styleClasses}
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default Testimonials;

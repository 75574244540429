import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Tabs,
    Tab,
    makeStyles,
    Card,
    CardContent,
    CardMedia,
    Button,
    Grid, // Imported Grid
    useMediaQuery,
    useTheme
} from '@material-ui/core';

import bannerVideo from "../assets/Team/Our_team_banner.mp4";
import gunjanImage from "../assets/Team/Gunjan_sinha.png";
import akritiImage from "../assets/Team/akriti.jpg";
import dheerajImage from "../assets/Team/Dheeraj Prasad.jpg";
import shellyeImage from "../assets/Team/Shellye Archambeau.jpg";
import davidImage from "../assets/Team/David Scott.jpg";
import mikeImage from "../assets/Team/Mike Rose.jpg";
import vinayImage from "../assets/Team/Vinay Kumar.jpg";
import rameshImage from "../assets/Team/Dr. Ramesh Raskar.jpg";
import rajendraImage from "../assets/Team/Prof. Rajendra Pratap Gupta.jpg";
import stevenImage from "../assets/Team/Steven M. Ferguson.jpg";
import vedikaImage from "../assets/Team/Vedika Mehrotra.jpg";

import shrithiImage from "../assets/Team/Shrithi.jpg";
import shriyaImage from "../assets/Team/Shriya.jpg";
import kanishkImage from "../assets/Team/Kanishk.jpg";
import mallikaImage from "../assets/Team/Mallika.jpg";
import manaliImage from "../assets/Team/Manali.jpg";
import minzaImage from "../assets/Team/Minza.jpg";
import mrittikaImage from "../assets/Team/Mrittika.jpg";
import nikitaImage from "../assets/Team/Nikita.jpg";
import ruchiImage from "../assets/Team/Ruchi.jpg";
import sakshiImage from "../assets/Team/Sakshi.jpg";
import saumiImage from "../assets/Team/Saumi b.jpg";
import shainaImage from "../assets/Team/Shaina.jpg";
import sheetalImage from "../assets/Team/Sheetal.jpg";
import shubhamImage from "../assets/Team/Shubham.jpg";
import sugataImage from "../assets/Team/Sugata.jpg";
import vanshikaImage from "../assets/Team/Vanshika.jpg";

import brittanyImage from "../assets/Team/Entrepreneurs/Brittany Sturla.jpg";
import danielleImage from "../assets/Team/Entrepreneurs/Danielle Coyle.jpg";
import jessicaImage from "../assets/Team/Entrepreneurs/Dr. Jessica Evert, M.D..jpg";
import riyaImage from "../assets/Team/Entrepreneurs/Riya Sinha.jpg";
import robinImage from "../assets/Team/Entrepreneurs/Robin Young.jpg";
import shraddhaImage from "../assets/Team/Entrepreneurs/Shraddha Varma.jpg";
import tajniImage from "../assets/Team/Entrepreneurs/Tajni Diller.jpg";
import utsavImage from "../assets/Team/Entrepreneurs/Utsav Mukherjee.jpg";
import jatinImage from "../assets/Team/Entrepreneurs/Jatin Saini.jpg";
import priyankaImage from "../assets/Team/Entrepreneurs/Priyanka Mishra.jpg";
import riaImage from "../assets/Team/Entrepreneurs/Ria Singhal.jpg";
import gregImage from "../assets/Team/Entrepreneurs/Greg Sherwin.jpg";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
// Import other advisor images as needed

import useGlobalStyles from '../styles/CourseStyles';

// TabPanel Component for conditional rendering
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(4),
        color: '#FFFFFF',
        position: 'relative',
        height: '550px',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: { // Tablet screens
            height: '450px',
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            flexDirection: 'column',
            justifyContent: 'center',
            height: 'auto',
            padding: theme.spacing(2),
        },
    },
    videoBackground: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transform: 'translate(-50%, -50%)',
        zIndex: 0,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
    },
    contentContainer: {
        position: 'relative',
        zIndex: 2,
        maxWidth: '50%',
        padding: theme.spacing(4),
        [theme.breakpoints.down('md')]: { // Tablet screens
            maxWidth: '40%',
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            maxWidth: '100%',
            textAlign: 'center',
            padding: theme.spacing(2),
        },
    },
    heading: {
        marginBottom: theme.spacing(1),
        fontWeight: 'bold',
        color: '#FFFFFF',
        width: 'auto',
        [theme.breakpoints.down('md')]: { // Tablet screens
            fontSize: '2rem',
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            fontSize: '1.5rem',
        },
    },
    description: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('md')]: { // Tablet screens
            fontSize: '1rem',
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            fontSize: '0.9rem',
            marginTop: theme.spacing(2),
        },
    },
    section: {
        marginTop: theme.spacing(6),
        backgroundColor: '#FFFFFF',
        color: '#25387c',
        textAlign: 'left',
        padding: theme.spacing(4),
        [theme.breakpoints.down('md')]: { // Tablet screens
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            padding: theme.spacing(2),
            textAlign: 'center',
        },
    },
    sectionHeading: {
        backgroundColor: '#25387c',
        color: '#fff',
        textAlign: 'center',
        padding: theme.spacing(3, 6),
        borderRadius: '4px',
        [theme.breakpoints.down('md')]: { // Tablet screens
            padding: theme.spacing(2, 5),
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            padding: theme.spacing(2, 4),
        },
    },
    tabsContainer: {
        marginTop: theme.spacing(8),
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            overflowX: 'auto', // Allow horizontal scrolling
            flexWrap: 'nowrap', // Prevent wrapping
        },
    },
    tab: {
        textTransform: 'none',
        fontWeight: '400',
        margin: theme.spacing(0.5, 2),
        '&.Mui-selected': {
            color: '#25387c',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto', // Allow tabs to shrink based on content
            padding: theme.spacing(1, 1.5),
        },
    },
    horizontalCard: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',    // Adjusted to fit within Grid item
        width: '100%',
        height: '100%',
        border: 'none',
        boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
        [theme.breakpoints.down('md')]: { // Tablet screens
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    cardMedia: {
        height: 180,
        width: 180,
        padding: theme.spacing(2),
        borderRadius: 32,
        [theme.breakpoints.down('md')]: { // Tablet screens
            height: 160,
            width: 160,
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            height: 140,
            width: 140,
        },
    },
    cardMediaAdvisor: {
        height: 160,
        width: 160,
        padding: theme.spacing(2),
        borderRadius: 32,
        [theme.breakpoints.down('md')]: { // Tablet screens
            height: 140,
            width: 140,
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            height: 120,
            width: 120,
        },
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '1',
        padding: theme.spacing(4, 2),
        [theme.breakpoints.down('md')]: { // Tablet screens
            padding: theme.spacing(3, 1.5),
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            padding: theme.spacing(2, 1),
        },
    },
    cardContentAdvisor: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '1',
        padding: theme.spacing(2, 1, 2, 0.5),
        [theme.breakpoints.down('md')]: { // Tablet screens
            padding: theme.spacing(1.5, 0.5, 1.5, 0.3),
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            padding: theme.spacing(1, 0.3, 1, 0.2),
        },
    },
    h5font: {
        padding: theme.spacing(0, 0),
        textAlign: 'left',
        [theme.breakpoints.down('md')]: { // Tablet screens
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            fontSize: '1.2rem',
            textAlign: 'center',
        },
    },
    h6font: {
        padding: theme.spacing(0, 0),
        textAlign: 'left',
        lineHeight: 1.3,
        [theme.breakpoints.down('md')]: { // Tablet screens
            fontSize: '1rem',
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            fontSize: '1.25rem',
            textAlign: 'center',
        },
    },
    subtitlefont: {
        padding: theme.spacing(0, 0),
        color: '#25387c',
        [theme.breakpoints.down('md')]: { // Tablet screens
            fontSize: '0.95rem',
        },
        [theme.breakpoints.down('sm')]: { // Small screens (mobile)
            fontSize: '0.9rem',
            textAlign: 'center',
        },
    },
    responsiveBox: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(5),
        marginBottom: theme.spacing(8),
        gap: theme.spacing(7), // Using theme.spacing for gap (56px if theme.spacing(7) === 56px)
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: theme.spacing(2), // Reduced gap for small screens
        },
    },
}));

const Team = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        const mainTitle = "Team | OpenGrowth.AI";
        const subtitle = "Meet the team behind OpenGrowth.AI, driving innovation with expertise in AI, startups, and technology to empower businesses and entrepreneurs worldwide.";
        document.title = `${mainTitle} - ${subtitle}`;
      }, []);
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <>
            {/* Main About Us Section with Background Image */}
            <Box className={classes.container}>
                {/* Video Background */}
                <video autoPlay muted loop className={classes.videoBackground} poster={bannerVideo}>
                    <source src={bannerVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                {/* Overlay */}
                <Box className={classes.overlay} />

                {/* Content Container */}
                <Box className={classes.contentContainer}>
                    <Typography variant="h3" className={`${styleClasses.h3font} ${classes.heading}`}>
                        Meet our Team
                    </Typography>
                    <Typography variant="body1" className={`${styleClasses.body1font}`}>
                        Visionaries, Advisors, and the Team driving our success
                    </Typography>
                    <Typography variant="h6" className={`${styleClasses.h6banner} ${classes.description}`}>
                    We are a global team based in Silicon Valley with expertise in Growth Leadership.
                    </Typography>
                </Box>
            </Box>

            {/* Tabs Section */}
            <Box className={classes.tabsContainer}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={isSmallScreen ? "scrollable" : "standard"} // Responsive variant
                    scrollButtons={isSmallScreen ? "auto" : "off"} // Responsive scroll buttons
                    aria-label="Team Categories Tabs"
                >
                    <Tab
                        label="Our Leadership "
                        className={`${styleClasses.h5fontPara} ${classes.tab}`}
                        id="tab-0"
                        aria-controls="tabpanel-0"
                    />
                    <Tab
                        label="Our Team"
                        className={`${styleClasses.h5fontPara} ${classes.tab}`}
                        id="tab-1"
                        aria-controls="tabpanel-0"
                    />
                    <Tab
                        label="Our Advisors"
                        className={`${styleClasses.h5fontPara} ${classes.tab}`}
                        id="tab-2"
                        aria-controls="tabpanel-1"
                    />
                    {/* Add more tabs as needed */}
                </Tabs>
            </Box>

            {/* Tab Panels */}
            <Box className={classes.section}>


            <TabPanel value={activeTab} index={0}>
                    <Typography variant="h4" className={`${styleClasses.h4font} ${classes.sectionHeading}`}>
                        Our Leadership Team
                    </Typography>
                    <Box m={5}>
                        <Grid container spacing={4}>

                        <Grid item xs={12} sm={5} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Akriti Verma"
                                        image={akritiImage}
                                        title="Akriti Verma"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                    <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                            Akriti Verma
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                            Co-founder, Director-OpenGrowth
                                            </Typography>
                                            {/* LinkedIn Icon */}
                                            <Box>
                                                <a href="https://www.linkedin.com/in/akriti-verma9/" target="_blank" rel="noopener noreferrer">
                                                    <LinkedInIcon className={classes.linkedInIcon} style={{fontSize: '2rem',fill: 'rgb(52 66 207)'}}/>
                                                </a>
                                            </Box>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                             {/* Advisor Card 2 */}
                             <Grid item xs={12} sm={5} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Dheeraj Prasad"
                                        image={dheerajImage}
                                        title="Dheeraj Prasad"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                            Dheeraj Prasad
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                            Executive Vice President OpenGrowth LLC
                                            </Typography>
                                            <Box>
                                                <a href="https://www.linkedin.com/in/dheerajprasad/" target="_blank" rel="noopener noreferrer">
                                                    <LinkedInIcon className={classes.linkedInIcon} style={{fontSize: '2rem', fill: 'rgb(52 66 207)'}}/>
                                                </a>
                                            </Box>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Vinay Kumar"
                                        image={vinayImage} // Replace with actual advisor image
                                        title="Vinay Kumar"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Vinay Kumar
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Technical Consultant
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>
                            

                            <Grid item xs={12} sm={5} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Vedika Mehrotra"
                                        image={vedikaImage}
                                        title="Vedika Mehrotra"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Vedika Mehrotra
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Business Head, OpenGrowth
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Sakshi Khanna"
                                        image={sakshiImage} // Replace with actual advisor image
                                        title="Sakshi Khanna"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Sakshi Khanna
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                HR Manager
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                    </Box>
                </TabPanel>
                {/* Our Team Tab Panel */}
                <TabPanel value={activeTab} index={1}>
                    <Typography variant="h4" className={`${styleClasses.h4font} ${classes.sectionHeading}`}>
                        Our Team
                    </Typography>
                    <Box m={5}>
                        <Grid container spacing={4}>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Mallika Khandelwal"
                                        image={mallikaImage} // Replace with actual advisor image
                                        title="Mallika Khandelwal"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Mallika Khandelwal
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                SEO Associate
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Manali Mehrish"
                                        image={manaliImage} // Replace with actual advisor image
                                        title="Manali Mehrish"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Manali Mehrish
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Content Manager
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Mrittika Mandal"
                                        image={mrittikaImage} // Replace with actual advisor image
                                        title="Mrittika Mandal"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Mrittika Mandal
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Content Writer
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Nikita Kumari"
                                        image={nikitaImage} // Replace with actual advisor image
                                        title="Nikita Kumari"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Nikita Kumari
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Web Developer
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Ruchi Pandey"
                                        image={ruchiImage} // Replace with actual advisor image
                                        title="Ruchi Pandey"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Ruchi Pandey
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Senior Software Developer
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Saumi Biswas"
                                        image={saumiImage} // Replace with actual advisor image
                                        title="Saumi Biswas"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Saumi Biswas
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Social Media Associate
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Shaina Kazi"
                                        image={shainaImage} // Replace with actual advisor image
                                        title="Shaina Kazi"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Shaina Kazi
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                HR Associate
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Sheetal Sahu"
                                        image={sheetalImage} // Replace with actual advisor image
                                        title="Sheetal Sahu"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Sheetal Sahu
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Content Developer Associate
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Shrithi Basumata"
                                        image={shrithiImage} // Replace with actual advisor image
                                        title="Shrithi Basumata"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Shrithi Basumata
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                React Js Developer
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={5} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Shriya Sinha"
                                        image={shriyaImage}
                                        title="Shriya Sinha"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Shriya Sinha
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Marketing Manager, OpenGrowth
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Shubham Raut"
                                        image={shubhamImage} // Replace with actual advisor image
                                        title="Shubham Raut"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Shubham Raut
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Project Lead, OpenGrowth
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={5} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Sugata Bhattacharya"
                                        image={sugataImage}
                                        title="Sugata Bhattacharya"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Sugata Bhattacharya
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                Learning & Development Manager
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Vanshika Yadav"
                                        image={vanshikaImage} // Replace with actual advisor image
                                        title="Vanshika Yadav"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                                Vanshika Yadav
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                                QA Tester
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </TabPanel>

                {/* Our Entrepreneurs Tab Panel */}
                <TabPanel value={activeTab} index={2}>
                    <Typography variant="h4" className={`${styleClasses.h4font} ${classes.sectionHeading}`}>
                        Our Advisors
                    </Typography>
                    <Box m={5}>
                        <Grid container spacing={4}>
                            {/* Advisor Card 1 */}

                            
                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Gunjan Sinha"
                                        image={gunjanImage} // Replace with actual advisor image
                                        title="Gunjan Sinha"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                            Gunjan Sinha
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                            Founder, OpenGrowth & Executive Chairman of MetricStream
                                            </Typography>
                                            <Box>
                                                <a href="https://www.linkedin.com/in/gunjanksinha/" target="_blank" rel="noopener noreferrer">
                                                    <LinkedInIcon className={classes.linkedInIcon} style={{fontSize: '2rem',fill: 'rgb(52 66 207)'}}/>
                                                </a>
                                            </Box>
                                        </Box>


                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Shellye Archambeau"
                                        image={shellyeImage} // Replace with actual advisor image
                                        title="Shellye Archambeau"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                            Shellye Archambeau
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                            Board Member, Nordstrom, Verizon, Roper Technologies and Okta
                                            </Typography>
                                            <Box>
                                                <a href="https://www.linkedin.com/in/shellye-archambeau/" target="_blank" rel="noopener noreferrer">
                                                    <LinkedInIcon className={classes.linkedInIcon} style={{fontSize: '2rem',fill: 'rgb(52 66 207)'}}/>
                                                </a>
                                            </Box>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            
                            <Grid item xs={12} sm={5} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="David Scott"
                                        image={davidImage}
                                        title="David Scott"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                            David Scott
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                            Strategic Advisor OpenGrowth
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>


                            {/* Advisor Card 3 */}
                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Greg Sherwin"
                                        image={gregImage} // Replace with actual advisor image
                                        title="Greg Sherwin"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                            Greg Sherwin
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                            Technology Advisor
                                            </Typography>
                                            <Box>
                                                <a href="https://www.linkedin.com/in/gregsherwin/" target="_blank" rel="noopener noreferrer">
                                                    <LinkedInIcon className={classes.linkedInIcon} style={{fontSize: '2rem',fill: 'rgb(52 66 207)'}}/>
                                                </a>
                                            </Box>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* 2nd Grid */}

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Mike Rose"
                                        image={mikeImage} // Replace with actual advisor image
                                        title="Mike Rose"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                            Mike Rose
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                            Managing Director, Montage Capital
                                            </Typography>
                                            <Box>
                                                <a href="https://www.linkedin.com/in/mike-rose-montage-capital/" target="_blank" rel="noopener noreferrer">
                                                    <LinkedInIcon className={classes.linkedInIcon} style={{fontSize: '2rem',fill: 'rgb(52 66 207)'}}/>
                                                </a>
                                            </Box>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Prof. Rajendra Pratap Gupta"
                                        image={rajendraImage} // Replace with actual advisor image
                                        title="Prof. Rajendra Pratap Gupta"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                            Prof. Rajendra Pratap Gupta
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                            Policy Maker & Author
                                            </Typography>
                                            <Box>
                                                <a href="https://www.linkedin.com/in/rajendragupta/" target="_blank" rel="noopener noreferrer">
                                                    <LinkedInIcon className={classes.linkedInIcon} style={{fontSize: '2rem',fill: 'rgb(52 66 207)'}}/>
                                                </a>
                                            </Box>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* 3rd Grid */}

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.horizontalCard}>
                                    <CardMedia
                                        className={classes.cardMediaAdvisor}
                                        component="img"
                                        alt="Dr. Ramesh Raskar"
                                        image={rameshImage} // Replace with actual advisor image
                                        title="Dr. Ramesh Raskar"
                                    />
                                    <CardContent className={classes.cardContentAdvisor}>
                                        <Box display="flex" flexDirection="column" gridGap={4}>
                                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`}>
                                            Dr. Ramesh Raskar
                                            </Typography>
                                            <Typography variant="body" className={styleClasses.body1font}>
                                            Associate Professor at MIT Media Lab
                                            </Typography>
                                            <Box>
                                                <a href="https://www.linkedin.com/in/raskar/" target="_blank" rel="noopener noreferrer">
                                                    <LinkedInIcon className={classes.linkedInIcon} style={{fontSize: '2rem',fill: 'rgb(52 66 207)'}}/>
                                                </a>
                                            </Box>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>


                        </Grid>
                    </Box>
                </TabPanel>
            </Box>
        </>
    );
};

export default Team;

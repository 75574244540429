import React, { useState, useEffect, Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Card, CardContent, CardHeader, Grid,Typography,makeStyles } from '@material-ui/core';
import { translateText } from "../../../Helper";
import { ProfessorRegister } from '../../../Routes';
import { ExpertRegister } from '../../../Routes';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';

const CarouselCard = React.lazy(() => import('../../../../webinar/login/CarouselCard'));
const useStyles = makeStyles(theme => ({
  link: {
	fontWeight:700,
    cursor: 'pointer',
    color: '#253B7C',
    textDecoration: 'none', 
    '&:hover': {
      textDecoration: 'underline', 
    },
  },
}));
const Register = () => {
	const classes = useStyles();
	const [success, setSuccess] = useState(false);
	const [unsuccess, setUnsuccess] = useState(false);
	const [msg, setMsg] = useState('');
	const [ipAddress, setIpAddress] = useState('');
    const history = useHistory();
    let config_res=cookie.load('config')	 

	useEffect(() => {
	fetch('https://ipapi.co/json/')
	.then(res => res.json())
	.then(data => {
	setIpAddress(data['ip']);
	})
	.catch(console.log);
	}, []);

	const ImgBox = () => {
	return (
	<Card elevation={0}>
	<CardContent>
	{window.location.pathname === "/register/user" ? (
	<Box>
	<img src="/assets/images/register.svg" alt="" height="400px" width="400px" />
	</Box>
	) : (
	<Box>
	<img src="/assets/images/data-entr.gif" alt="" height="300px" width="500px" />
	</Box>
	)}
	</CardContent>
	</Card>
	);
	};

	const ProfessorForm = () => {
	return (
	<Card>
	<CardHeader title={translateText('SIGN_UP_TITLE1')} />
	<CardContent>
	<Suspense fallback={<div></div>}>
	{/* <ProfessorRegister /> */}
	<ExpertRegister />
	</Suspense>
	</CardContent>
	</Card>
	);
	};


	return (
	<Box padding={3}>
	<Grid container spacing={3} justifyContent="center" alignItems="center">
	<Grid item xs={0} md={5}>
	<Suspense fallback={<div> </div>}>
	<CarouselCard />
	</Suspense>
	</Grid>

	
		
		<Grid item xs={10} md={7}>
		  {
			1>0 || config_res && (config_res.filter(item => item.component === "signin").map(item => item.enable)[0])
			&&
			(
				<Box marginBottom={0}  display="flex" justifyContent="end">
				 <Typography color="textSecondary" variant="subtitle2">
				Already Registered?
				<span className={classes.link} 
				onClick={() => {window.location.pathname="/"}}> Login Now! </span>
			  </Typography>
			  </Box>
			  )
		  }
		  
				<Box marginBottom={1}  display="flex" justifyContent="end">
				{
					1>0 || config_res && (config_res.filter(item => item.component === "signup-learner").map(item => item.enable)[0])
					&&
					(
					<Typography color="textSecondary" variant="subtitle2">
					Are you seeking to join as a learner? 
					<span className={classes.link} onClick={() => {history.push("/register/user")}}> Enroll here! </span>
				    </Typography>
				   )
		       }
			  </Box>
			
		  <Box>
			<Suspense fallback={<div> </div>}>
			  <ProfessorForm />
			</Suspense>
		  </Box>
		</Grid>

			
		  
		
	  </Grid>
	</Box>
	);
	};

export default Register;
// src/data/faqData.js

export const defaultFaqData = [
    {
      question: 'What is online learning at OpenGrowth Startup Academy like?',
      answer:
        'Online learning at OpenGrowth allows you to study from anywhere using your computer or device. You can access videos, reading materials, and interactive activities. This format encourages self-motivation and flexibility, enabling you to balance your studies with work or personal commitments.',
    },
    {
      question: 'What types of courses does OpenGrowth offer?',
      answer:
        'At OpenGrowth, we provide a diverse range of courses designed to meet the needs of both businesses and individuals. For businesses, our offerings include leadership development, team management, digital transformation, project management, and employee upskilling in technical and soft skills. For individuals, we offer courses in personal growth, professional skills, leadership, digital marketing, business management, and more. Each program is carefully crafted to enhance skills, drive career growth, and unlock potential.',
    },
    {
      question: 'Are all the courses available online?',
      answer:
        'Yes. Once enrolled, you have lifetime access to all course materials, allowing you to learn at your own pace and revisit the content whenever you need.',
    },
    {
      question: 'How can students access the course materials?',
      answer:
        'Enrolling in a course is simple! Click on the "Get Started" button on any course card, fill in your details, and complete the payment process to gain immediate access to the course materials.',
    },
    {
      question: 'Will I receive a certificate upon completion of the course?',
      answer:
        'Yes, upon successful completion of any course, you will receive a certificate that you can share on your professional networks or with potential employers.',
    },
  ];
  
  export const contextFaqData = {
    allexpert: [
      {
        question: 'What is Growth Selling?',
        answer: 'Growth Selling combines Agentic AI, Expert Intelligence, and Growth Expert Networks to drive revenue growth on a pay-as-you-go model.',
      },
      {
        question: 'How does Growth Selling work?',
        answer: 'It uses AI GTM Agents for automation, Expert Intelligence for data-driven insights, and Growth Expert Networks for building trust-based relationships.',
      },
      {
        question: 'What are AI GTM Agents? ',
        answer: 'These are configurable AI tools that handle multiple Go-to-Market (GTM) functions, offering tailored solutions for various business needs.',
      },
      {
        question: 'What is Expert Intelligence in Growth Selling?',
        answer: 'It involves leveraging expert talent to shift focus from product features to delivering real customer outcomes and sustainable growth.',
      },
      {
        question: 'What role do Growth Expert Networks play?',
        answer: 'They bring experienced professionals who combine their expertise with AI to train and validate GTM models while fostering trust-based selling.',
      },
      {
        question: 'Can I hire Growth Experts or AI Agents on demand?',
        answer: 'Yes, you can fractionally hire experts or AI agents to access expertise and tools as needed without full-time commitments.',
      },
      {
        question: 'Who can benefit from Growth Selling?',
        answer: 'B2B companies, startups, and enterprises looking to accelerate their Go-to-Market strategies and achieve sustainable revenue growth.',
      },
      {
        question: 'Is Growth Selling scalable?',
        answer: 'Yes, it’s flexible and can be tailored to fit businesses of all sizes and industries.',
      },
      // Add more FAQs for AllExpert as needed
    ],
    businessCourse: [
      {
        question: 'What courses are available for business?',
        answer: 'We offer a range of courses tailored for businesses, including leadership development, team management, digital transformation, project management, and employee upskilling in various technical and soft skills.',
      },
      {
        question: 'Do you offer corporate discounts for multiple employees/ teams?',
        answer: 'Yes, we offer special pricing for businesses enrolling multiple employees. Contact us to learn more about bulk enrollment discounts.',
      },
      {
        question: 'How can we measure the impact of training on employee performance?',
        answer: 'We provide detailed reporting on employee progress, completion rates, and post-training assessments to help you evaluate the effectiveness of the courses.',
      },
      {
        question: 'Can our team access courses on different devices or platforms?',
        answer: 'Absolutely. Our courses are accessible on desktops, laptops, tablets, and smartphones, so your team can learn anytime, anywhere.',
      },
      {
        question: 'What kind of post-training support do you provide for businesses?',
        answer: 'We offer continued access to course materials and provide support for follow-up queries. We also offer refresher sessions and additional resources to ensure long-term retention of skills.',
      },
      {
        question: 'Do you offer certifications for employees who complete the courses?',
        answer: 'Yes, employees who complete our courses receive certification, which they can use to enhance their professional profiles.',
      },
      {
        question: 'What industries do your business courses cater to?',
        answer: 'Our courses are designed to be adaptable to various streams like AI, startups, marketing, and entrepreneurship.',
      },
      {
        question: 'How do we get started with Opengrowth’s business training?',
        answer: 'Simply contact us through our website or sign up directly for the business courses of your choice. We will guide you through enrolling your team and setting up the right training program for your organization.',
      },
      // Add more FAQs for Business Course as needed
    ],
    individualCourse: [
      {
        question: 'What types of courses do you offer for individuals?',
        answer: 'Opengrowth offers various courses for personal development, professional skills, leadership, digital marketing, business management, and more. Our courses are designed to enhance your skills and career prospects.',
      },
      {
        question: 'What makes Opengrowth courses different from others?',
        answer: 'Open growth courses stand out because of our practical approach. We focus on real-world applications and current industry trends, ensuring that what you learn is relevant. Additionally, our instructors are industry professionals, providing insights you might not get elsewhere.',
      },
      {
        question: 'Are the courses self-paced or instructor-led?',
        answer: 'We offer both self-paced and instructor-led courses, allowing you to choose the format that best fits your learning style and schedule.',
      },
      {
        question: 'Who are the course instructors?',
        answer: 'The courses are led by experienced experts with industry experience, ensuring you learn from the best.',
      },
      {
        question: 'Do I need any prior knowledge to enroll in your courses?',
        answer: 'Most of our courses are designed for all skill levels, but some advanced courses may require prior knowledge. Check the course details for specific prerequisites.',
      },
      {
        question: 'How can I access the course materials?',
        answer: 'All course materials are available online. Once you enroll, you’ll have access to videos, readings, and assignments in the form of activities and quizzes through our learning platform.',
      },
      {
        question: 'What is the typical duration of a course?',
        answer: 'Course duration varies depending on the subject and format. The courses can be completed at your own speed, typically lasting between 4 to 12 weeks.',
      },
      {
        question: 'Will I receive a certificate from Opengrowth Academy upon completion?',
        answer: 'Yes, upon successful completion of any course, you will receive a certificate that you can share on your professional networks or with potential employers.',
      },
      {
        question: 'Can I retake lessons if I need extra help?',
        answer: 'Yes, you can revisit lessons and materials as many times as you need while you are enrolled in the course.',
      },
      {
        question: 'How do I pay for a course, and are there any payment plans available?',
        answer: 'You can pay for courses via credit card, PayPal, or bank transfer. We also offer flexible payment plans for select courses to make learning more accessible.',
      },
      // Add more FAQs for Individual Course as needed
    ],
    contentMarketing: [
      {
        question: 'What are Venture Assistance Services? ',
        answer: 'Venture Assistance Services provide a comprehensive range of support for entrepreneurs, including social media, marketing, payroll, human resources, finance & accounting, and legal services. These services ensure businesses remain compliant with local regulations and help entrepreneurs focus on their core operations.',
      },
      {
        question: 'Who can benefit from Venture Assistance Services?',
        answer: "Entrepreneurs and startups, especially those with global expansion plans or seeking to streamline operations, can benefit from these services.",
      },
      {
        question: 'How do Venture Assistance Services help with compliance?',
        answer: 'Our services ensure your business adheres to local regulations by managing critical aspects like payroll, taxes, legal requirements, and more, so you can expand confidently.',
      },
      {
        question: 'Are the services customizable to my business needs?',
        answer: ' Yes, our services are tailored to meet the unique requirements of your business, whether it’s marketing, finance, or legal support.',
      },
      {
        question: 'How do I access Venture Assistance Services?',
        answer: 'Simply fill out the inquiry form on our website, and our team will get in touch to discuss your needs and provide a customized plan.',
      },
      {
        question: 'What industries do you support with Venture Assistance Services?',
        answer: 'We work across various industries, providing flexible support for entrepreneurs and startups in any field looking to optimize their operations.',
      },
      // Add more FAQs for Content Marketing as needed
    ],
  };
  
import React, { useState,useEffect } from "react";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from "./useStyles";

const useStylesNew  = makeStyles((theme) => ({
  btn: { fontSize: "0.85rem"  },
  iconsbg: {
    backgroundColor: '#0000001f',
    padding: '8px',
    marginBottom: '8px'
  },
  dialog_paper:{borderRadius:'12px'}
}));

const ArticleDialog = (props) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const classesnew = useStylesNew()

  const handleClose = () => {
    setOpen(false);
  }
  useEffect(() => {
	//   alert(1)
    setOpen(props.text?true:false); 
  }, [props.text]);

  const ContentOnly = () => {
    return (
      <React.Fragment>
        <Box width="100%" display="flex" flexDirection="row">
          <Box flexGrow={1} paddingBottom={1}>
            <Typography variant="h6" color='primary'>
              <Box fontWeight="600" className={classes.name}>
                Information
              </Box>
            </Typography>
          </Box>
          <Box padding={1} >
            <IconButton
              onClick={handleClose}
              className={classesnew.iconsbg}
            >
              <CloseIcon color="grey" className={classesnew.btn} />
            </IconButton>
          </Box>
        </Box>
        <Typography variant="subtitle1" color="textSecondary" component="p">
          <Box lineHeight={1.5} padding="0 0 16px">
            {props.text}
          </Box>
        </Typography>
      </React.Fragment>
    );
  }

  const WithDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        classes={{ paper: classesnew.dialog_paper }}
        maxWidth="sm"
      >
        <DialogContent>
          <ContentOnly />
        </DialogContent>
      </Dialog>
    );
  }

  return <WithDialog />;
}

export default ArticleDialog; 
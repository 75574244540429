
import React, { useState, useRef, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import {
    Box,
    Typography,
    TextField,
    Button,
    Chip,
    Grid,
    Card,
    CardContent,
    Avatar,
    Divider,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import individual_course from '../assets/individual_course.png';
import CommentIcon from '@material-ui/icons/Comment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import individual_course_2 from '../assets/individual_course_2.jpg';
import PromotionalCard from './CourseFeature';
import FAQSection from '../Experts/FaqSection';
import Testimonials from '../Homepage/Testimonials';
import useGlobalStyles from '../styles/CourseStyles';
import BrowseAllCourses from './AllCourseCard';
import courses from './CourseData';
// import Header from '../signup-login/Header';
// import Footer from '../signup-login/Footer';


const Banner = ({ onExploreNow }) => { // Destructure the prop
    const classes = useGlobalStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isIpad = useMediaQuery('(min-width:768px) and (max-width:1024px)'); // Detect iPad screen sizes

    return (
        <Box className={classes.bannerContainer}>
            <Box className={classes.bannerTextContainer}>
                <Typography
                    variant="h3"
                    className={classes.h3font}
                >
                    Jumpstart Your Career or Reinvent Yourself with Our <span className={classes.span}>Online Courses</span>
                </Typography>
                <Typography variant="h6" className={`${classes.h6banner} ${classes.h6HeadingDesc}`}>
                    We offer practical learning experience by transforming industry-leading
                    experts’ strategies, tactics, and insights across various disciplines, into
                    certified courses with social and collaborative elements, designed to
                    upskill individuals and help them excel in their respective fields.
                </Typography>
                <Box className={`${classes.filterChipsContainer}`}>
                    {/* "Explore Now" button calls the scroll handler */}
                    <Button
                        variant="contained"
                        onClick={onExploreNow} // Attach the handler
                        style={{margin: 8}}
                        className={classes.businessButton}
                    >
                        Explore Now
                    </Button>
                    <Button
                        variant="contained"
                        component={Link} // Use Link component for navigation
                        to="/growth-academy"
                        style={{margin: 8}}
                        className={classes.individualButton}
                    >
                        All Courses
                    </Button>
                </Box>
            </Box>
            <Box className={classes.bannerImageContainer}>
                <img
                    src={individual_course}
                    alt="Banner Image"
                    className={classes.bannerImage}
                />
            </Box>
        </Box>
    );
};

const CourseFeatures = () => {
    useEffect(() => {
        const mainTitle = 'Best Online Courses for Individuals | OpenGrowth.AI ';
        const subtitle = 'Explore the best online courses for individuals at OpenGrowth.AI. Gain valuable skills and knowledge to achieve your business goals.';
        document.title = `${mainTitle} - ${subtitle}`;
      }, []);
    const classes = useGlobalStyles();
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
    const features = [
        {
            title: 'Improved Employability',
            description:
                'Experiential courses offer hands-on, real-world learning, helping individuals gain industry-relevant skills and problem-solving abilities.',
        },
        {
            title: 'Exposure to Industry Experts',
            description:
                'Learning from industry experts equips individuals with real-world insights, up-to-date skills, and access to the latest trends.',
        },
        {
            title: 'Self Paced Learning',
            description:
                'Self-paced learning is more effective because it offers flexibility, personalized experiences, increased engagement, better retention through repeated review, and reduced pressure from rigid timelines',
        },
        {
            title: 'Memorable Learning Experience',
            description:
                'Memorable learning experiences enhance retention by engaging emotions and making information more impactful. Hands-on activities and storytelling create stronger brain connections, making recall easier',
        },
    ];

    return (
        <Box className={classes.courseFeaturesContainer}>
            <Typography variant="h4" className={classes.h4font} align='left'>
                Course Benefits
            </Typography>
            <Grid container spacing={4} alignItems="stretch">
                {/* Left Side: Heading and Image */}
                <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Box style={{ marginBottom: 16 }}>
                        <Typography variant="h6" className={classes.description}>
                            Level up your game plan with courses designed to help
                            you learn by doing. Interact and learn with like minded
                            learners from diverse backgrounds.
                        </Typography>
                    </Box>
                    <Box className={classes.courseFeatureImgBox}>
                        <img
                            src={individual_course_2}
                            alt="Student learning"
                            className={classes.courseFeatureImg}
                        />
                    </Box>
                </Grid>

                {/* Right Side: Feature Cards */}
                <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={2} style={{ flexGrow: 1 }}>
                        {features.map((feature, index) => (
                            <Grid item xs={12} key={index} style={{ flexGrow: 1 }}>
                                <Card className={classes.featureCard}>
                                    <CardContent style={{ padding: 0, flexGrow: 1 }}>
                                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 8, width: 'fit-content' }}>
                                            {/* Custom Arrow */}
                                            <Box className={classes.customArrow}>
                                                &gt; {/* ">" symbol */}
                                            </Box>
                                            <Typography
                                                variant="h5"
                                                className={classes.h5font}
                                            >
                                                {feature.title}
                                            </Typography>
                                        </Box>
                                        <Typography variant="subtitle1" className={classes.subtitlefont}>
                                            {feature.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

// Main AllCourses Component
const IndividualCourse = () => {
    const classes = useGlobalStyles();
    const sliderRef = useRef(null);
    const [search, setSearch] = useState('');
    const location = useLocation();

    // Create a ref for the BrowseAllCourses section
    const browseAllCoursesRef = useRef(null);

    const theme = useTheme();
    const { course } = location.state || {};
    const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
    const isMedium = useMediaQuery(theme.breakpoints.between("sm", "lg"));
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const getSlidesToShow = () => {
        if (isLarge) return 3;
        if (isMedium) return 2;
        if (isSmall) return 1;
        return 4;
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: getSlidesToShow(),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: theme.breakpoints.values.lg,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: theme.breakpoints.values.sm,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // Scroll handler to scroll to BrowseAllCourses
    const scrollToBrowseAllCourses = () => {
        if (browseAllCoursesRef.current) {
            browseAllCoursesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box style={{ padding: 8, paddingTop: 0 }}>
            {/* <Header /> */}
            {/* Pass the scroll handler to Banner */}
            <Banner onExploreNow={scrollToBrowseAllCourses} />
            <CourseFeatures />

            {/* Browse All Courses Section with ref */}
            <Box ref={browseAllCoursesRef}>
                <BrowseAllCourses courses={courses} context={'individual'} />
            </Box>

            <PromotionalCard />
            {/* <Testimonials context='individualcourse' /> */}
            <FAQSection context="individualCourse" />
            {/* <Footer /> */}
        </Box>
    );
};

export default IndividualCourse;
import React, {useState} from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  makeStyles,
  Paper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import TickIcon from '../assets/Venture/tick_icon.png';
import useGlobalStyles from '../styles/CourseStyles';
import { PopupButton } from 'react-calendly';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(6),
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    // Make the table horizontally scrollable on small screens
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
    },
  },
  table: {
    minWidth: 650,
    // Adjust table layout for smaller screens
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      display: 'block',
    },
  },
  headerCell: {
    backgroundColor: '#f9bb02',
    color: '#25387c',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: theme.spacing(1),
    },
  },
  sideHeaderCell: {
    display: 'revert',
    borderRadius: '0',
    backgroundColor: '#f4f4f4',
    textAlign: 'left',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      fontSize: '14px',
    },
  },
  cell: {
    textAlign: 'center',
    fontSize: '16px',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: theme.spacing(1),
    },
  },
  tickIcon: {
    width: '20px',
    marginRight: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: '#FFC107',
    color: '#25387c',
    '&:hover': {
      backgroundColor: '#e6b800',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      fontSize: '14px',
    },
  },
  promoButton : {
    padding: theme.spacing(1, 4),
  }
}));

const PricingTable = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [calendlyUrl] = useState('https://calendly.com/vedika-5m0y/30min?month=2024-10');

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={`${styleClasses.h5fontPara} ${classes.headerCell}`}></TableCell>
            <TableCell className={`${styleClasses.h5fontPara} ${classes.headerCell}`}>Starter</TableCell>
            <TableCell className={`${styleClasses.h5fontPara} ${classes.headerCell}`}>Growth</TableCell>
            <TableCell className={`${styleClasses.h5fontPara} ${classes.headerCell}`}>Scale</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={`${styleClasses.h6font} ${classes.sideHeaderCell}`}>Inclusions</TableCell>
            <TableCell className={styleClasses.body1font}>
              - 6 hours of expert consultation per week<br />
              - Scheduled weekly sessions<br />
              - Dedicated support team
            </TableCell>
            <TableCell className={styleClasses.body1font}>
              - 12 hours of expert support per week<br />
              - Free dedicated Growth Associate for support<br />
              - Priority scheduling
            </TableCell>
            <TableCell className={styleClasses.body1font}>
              - Tailored services designed for your business needs<br />
              - Dedicated Growth Associate to work on execution<br />
              - Expert collaboration for high-impact projects
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={`${styleClasses.h6font} ${classes.sideHeaderCell}`}>Who is a Growth Expert?</TableCell>
            <TableCell className={styleClasses.body1font}>
              A seasoned professional specializing in strategy, leadership, and identifying opportunities for growth
            </TableCell>
            <TableCell className={styleClasses.body1font}>
              Works closely with businesses to solve strategic challenges and build sustainable growth models.
            </TableCell>
            <TableCell className={styleClasses.body1font}>
              Brings years of experience in fractional leadership roles for high-impact results.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={`${styleClasses.h6font} ${classes.sideHeaderCell}`}>Who is a Growth Associate?</TableCell>
            <TableCell className={styleClasses.body1font}>Not included in this plan.</TableCell>
            <TableCell className={styleClasses.body1font}>
              A dedicated team member focused on operational tasks, market research, and daily execution to free up leadership for strategic decisions.
            </TableCell>
            <TableCell className={styleClasses.body1font}>
              Handles end-to-end implementation, enabling businesses to scale efficiently and achieve faster results.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={`${styleClasses.h6font} ${classes.sideHeaderCell}`}>AI-Powered Services</TableCell>
            <TableCell className={styleClasses.body1font}>
              Includes AI-powered tools for data analysis and process automation.
            </TableCell>
            <TableCell className={styleClasses.body1font}>
              Advanced AI-driven strategies to enhance decision-making and maximize efficiency.
            </TableCell>
            <TableCell className={styleClasses.body1font}>
              Custom AI-based solutions for scalability, market insights, and performance optimization.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.sideHeaderCell}></TableCell>
            <TableCell className={classes.cell}>
            <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Get Started"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
            </TableCell>
            <TableCell className={classes.cell}>
            <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Get Started"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
            </TableCell>
            <TableCell className={classes.cell}>
            <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Get Started"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingTable;

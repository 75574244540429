// src/components/Homepage.js

import React, { useCallback, useEffect, useState, Suspense, lazy } from "react";
import axios from "axios";
import {
    Box,
    CircularProgress,
    Typography,
    Button,
    useMediaQuery,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton
} from "@material-ui/core"; // MUI v4 imports
import { makeStyles, useTheme } from "@material-ui/core/styles"; // MUI v4 styling
import CloseIcon from '@material-ui/icons/Close';
import bannerVideo from '../assets/Homepagevideo.mp4';
import { PopupWidget, PopupButton } from 'react-calendly';
import useGlobalStyles from "../styles/CourseStyles";
import { Link } from "react-router-dom";
import courses from '../Courses/CourseData';
import ExpertConnectRegister from "../Experts";
import { blogData } from "./BlogData";
import LazyLoadComponent from './LazyLoadedComponent'; // Import the LazyLoadComponent

// Lazy loaded components
const ExpertCarousel = lazy(() => import("./ExpertCarousel"));
const TrendingBlogs = lazy(() => import("../Experts/TrendingBlogs"));
const CoursesComponent = lazy(() => import("./Courses"));
const Hub = lazy(() => import("./Hub"));
const Testimonials = lazy(() => import("./Testimonials"));
const GrowthStudio = lazy(() => import("./GrowthStudio"));
const ContentMarketingPage = lazy(() => import("./ContentMarketingPage"));
const OpengrowthAI = lazy(() => import("./OpengrowthAI"));
const CardCarousel = lazy(() => import("./CardCarousel"));

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
    homepageContainer: {
        overflowX: "hidden",
        padding: "0px 8px",
    },
    bannerContainer: {
        position: "relative",
        display: "flex",
        border: 'none',
        borderRadius: "4px",
        overflow: "hidden",
        backgroundColor: "#051949", // Fallback color
        [theme.breakpoints.up("md")]: {
            height: "auto", // Set to viewport height for full screen
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto",
        },
    },
    h1font: {
        fontWeight: "500",
        color: '#f9bb02',
        border: 'none',
        [theme.breakpoints.down("sm")]: {
            textAlign: 'left',
        },
    },
    h5font: {
        color: '#f9bb02',
        fontWeight: 400,
        textAlign: 'left',
        lineHeight: '1.4',
    },
    subtitle2: {
        [theme.breakpoints.down("sm")]: {
            width: '90%',
        },
    },
    videoBox: {
        // Styles for fallback
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#051949", // Match the fallback background color
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
    },
    video: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        zIndex: 1,
    },
    contentBox: {
        position: "relative",
        zIndex: 3,
        width: "70%", // Adjust as needed
        color: "white",
        textAlign: 'left',
        paddingTop: 80,
        paddingBottom: 88,
        paddingLeft: 32,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            width: "fit-content",
        },
        [theme.breakpoints.between("sm", "md")]: {
            paddingLeft: theme.spacing(4),
        },
    },
    bannerText: {
        marginBottom: theme.spacing(1),
        border: 'none',
        "&::after": {
            border: 'none',
        },
    },
    bannerButton: {
        marginTop: theme.spacing(2),
        borderRadius: "50px",
        backgroundColor: "#f9bb02",
        color: "black",
        fontWeight: 600,
        fontSize: '1rem',
        padding: '6px 16px',
        textTransform: "none",
        cursor: 'pointer',
        "&:hover": {
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#f9bb02",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.8rem",
            padding: "6px 12px",
        },
    },
    button1: {
        padding: '8px 12px',
        cursor: "pointer",
        "&:hover": {
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#e7be46",
            transform: "translateY(-2px)",
        },
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '2em',
        gap: 24,
        [theme.breakpoints.down("sm")]: {
            gap: 8,
            marginTop: '0em',
            flexDirection: 'column',
        },
    }
}));

// Define VideoBackground as a separate component within the same file
const VideoBackground = () => {
    const classes = useStyles();

    return (
        <Box className={classes.videoBox}>
            <video
                className={classes.video}
                src={bannerVideo}
                autoPlay
                muted
                loop
                playsInline
                preload="metadata" // Adjust preload as needed
                poster="/path/to/poster-image.jpg" // Optional: Add a poster image for better UX
            >
                Your browser does not support the video tag.
            </video>
        </Box>
    );
};

// BannerSlide Component with Video as Background
const BannerSlide = ({ onRegisterClick }) => { // Receive handler as prop
    useEffect(() => {
        const mainTitle = 'OpenGrowth.AI | Grow with Fractional';
        const subtitle = 'Welcome to OpenGrowth.AI – Empowering startups with AI-driven solutions for Growth. Explore Expert led services, Ai Agents and Associates.';
        document.title = `${mainTitle} - ${subtitle}`;
      }, []);
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    // State to control the visibility of the Calendly popup
    const [calendlyOpen, setCalendlyOpen] = useState(false);

    // Function to open Calendly modal
    const openCalendlyModal = () => {
        setCalendlyOpen(true);
    };

    return (
        <Box className={`${styleClasses.bannerContainer} ${classes.bannerContainer}`}>
            {/* Lazy Loaded Video Background */}
            <Suspense fallback={
                <Box className={classes.videoBox}>
                    <CircularProgress color="secondary" />
                </Box>
            }>
                <VideoBackground />
            </Suspense>

            {/* Overlay Content */}
            <Box className={classes.contentBox}>
                <Typography
                    variant={isSmall ? "h1" : "h1"} // Adjust variant for responsiveness
                    gutterBottom
                    className={`${styleClasses.h1heading} ${classes.h1font}`}
                >
                    Grow with Fractional
                </Typography>
                <Typography
                    variant={isSmall ? "body1" : "h4"}
                    paragraph
                    className={`${styleClasses.h5fontPara} ${classes.h5font}`}
                >
                    Scale Your Sales, Not Your Overheads..
                    <br/> (Your next hire does not need to be full-time!)

                </Typography>
                <Typography
                    variant={isSmall ? "body1" : "h6"}
                    paragraph
                    className={`${styleClasses.h6banner} ${classes.subtitle2}`}
                >
                    Hire Pay-as-you-go AI Growth Agents and Growth Experts to drive Revenue Growth
                </Typography>
                <Box className={classes.buttonContainer}>
                    <PopupButton
                        url="https://calendly.com/opengrowth-support/30min"
                        rootElement={document.getElementById('root')}
                        text="Book a Discovery Call"
                        className={`${styleClasses.individualButton} ${classes.button1}`}
                    />
                    <Button
                        variant="contained"
                        className={`${styleClasses.individualButton} ${classes.button1}`}
                        component={Link}
                        to="/register/expert"
                    >
                        Join as an Expert
                    </Button>
                </Box>
            </Box>

            {/* Calendly PopupWidget */}
            {calendlyOpen && (
                <PopupWidget
                    url="https://calendly.com/vedika-5m0y/30min?month=2024-10"
                    rootElement={document.getElementById('root')}
                    onModalClose={() => setCalendlyOpen(false)} // Close the modal when user closes it
                />
            )}
        </Box>
    );
};

function OgHomepage() {
    const classes = useStyles();
    const [experts, setExperts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [registerOpen, setRegisterOpen] = useState(false); // State for modal

    const fetchExperts = useCallback(async (category = null) => {
        setLoading(true);
        try {
            const userEmail = "your-email@example.com"; // Replace with actual user email
            const token = "your-api-token"; // Replace with your actual token

            let response;
            if (category) {
                response = await axios.post(
                    "/api/search_mentor",
                    {
                        email: userEmail,
                        start: 0,
                        end: 16,
                        key: `0_popular_tags_${category}`,
                        search: category,
                        search_with: "tags",
                        action: "",
                        token: token,
                    }
                );
            } else {
                response = await axios.post(
                    "/api/get_all_mentors",
                    {
                        id: userEmail,
                        start: 0,
                        end: 16,
                        key: "0_all_mentors_0_to_10",
                    }
                );
            }
            setExperts(response.data);
        } catch (error) {
            console.error("Error fetching experts:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchExperts();
    }, [fetchExperts]);

    const handleRegisterOpen = () => {
        setRegisterOpen(true);
    };

    const handleRegisterClose = () => {
        setRegisterOpen(false);
    };

    return (
        <Box className={classes.homepageContainer}>
            {/* <Header /> */}
            <BannerSlide onRegisterClick={handleRegisterOpen} /> {/* Pass handler */}

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <ExpertCarousel experts={experts} />
                    <LazyLoadComponent>
                        <CardCarousel />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <GrowthStudio />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <CoursesComponent course={courses} />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <ContentMarketingPage />
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                        <TrendingBlogs blogs={blogData} />
                    </LazyLoadComponent>
                    {/* <LazyLoadComponent>
                        <Hub course={communities} />
                    </LazyLoadComponent> */}
                    {/* <LazyLoadComponent>
                        <Testimonials />
                    </LazyLoadComponent> */}
                </>
            )}

            {/* Register Dialog */}
            <Dialog
                open={registerOpen}
                onClose={handleRegisterClose}
                aria-labelledby="register-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="register-dialog-title">
                    Join as an Expert
                    <IconButton
                        aria-label="close"
                        onClick={handleRegisterClose}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ExpertConnectRegister onClose={handleRegisterClose} />
                </DialogContent>
            </Dialog>
            {/* <Footer /> */}
        </Box>
    );
}

export default OgHomepage;

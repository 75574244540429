// ScrollableTabsButtonPrevent.jsx

import React, { Suspense, useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import {
  FaRegCalendarMinus,
  FaRegCalendarAlt,
  FaRegCalendarCheck,
  FaRegCalendarTimes,
} from 'react-icons/fa';

// Lazy load the child components
const UpcomingRequest = React.lazy(() => import('./UpcomingRequest'));
const PendingRequest = React.lazy(() => import('./PendingRequest'));
const RejectedRequest = React.lazy(() => import('./RejectedRequest'));

// Define default counts
const def = { upcoming: 0, pending: 0, rejected: 0, attended: 0 };

// Styles
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, width: '100%' },
  global: {
    textTransform: 'capitalize',
    color: 'rgb(99, 115, 129)',
    textAlign: 'center',
    fontSize: '0.87rem',
  },
  m: { marginLeft: 'auto' },
  width: { minWidth: '120px' },
  tab: { verticalAlign: 'middle', marginRight: '8px', display: 'unset' },
  upcoming: {
    color: '#4caf50', // successColor
  },
  pending: {
    color: '#ff9800', // secondaryColor
  },
  rejected: {
    color: '#f44336', // notifyColor
  },
  attended: {
    color: '#3f51b5', // primaryColor (or any color you prefer)
  },
  transparent: { backgroundColor: 'transparent' },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
}));

// TabPanel Component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Accessibility Props
function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

export default function ScrollableTabsButtonPrevent() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [tabCount, setTabCount] = useState(def);

  // State for categorized data
  const [categorizedData, setCategorizedData] = useState({
    upcoming: [],
    pending: [],
    rejected: [],
    attended: [],
  });

  // Loading and Error States
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Retrieve user info from localStorage
  const getUserInfo = () => {
    const userStr = localStorage.getItem('ac_login_user');
    if (!userStr) {
      return {
        role: 'learner',
        fname: '',
        lname: '',
        email: '',
        id: null,
      };
    }

    try {
      const user = JSON.parse(userStr);
      return {
        role: user.role || 'learner',
        fname: user.fname || '',
        lname: user.lname || '',
        email: user.email || '',
        id: user.id || null,
      };
    } catch (error) {
      console.error('Error parsing ac_login_user:', error);
      return {
        role: 'learner',
        fname: '',
        lname: '',
        email: '',
        id: null,
      };
    }
  };

  const { role: userRole, fname: userFname, lname: userLname, email: userEmail, id: userId } = getUserInfo();
  const currentUserFullName = `${userFname} ${userLname}`.trim();
  console.log(`Current User Role: ${userRole}`);
  console.log(`Current User Full Name: ${currentUserFullName}`);
  console.log(`User Email: ${userEmail}`);

  // Function to get action from URL
  const getActionFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const action = parseInt(urlParams.get('action'), 10) || 0;
    console.log('Action from URL:', action);
    return action;
  };

  // Function to categorize appointments
  const categorizeAppointments = (callRequests) => {
    const categorized = {
      upcoming: [],
      pending: [],
      rejected: [],
      attended: [],
    };

    callRequests.forEach((request) => {
      console.log(request);
      
      // Normalize status to lowercase to avoid case sensitivity issues
      const status = request.status ? request.status.toLowerCase() : 'pending'; // default to 'pending' if not provided

      if (status === 'upcoming' || status === 'approved') {
        categorized.upcoming.push(request);
      } else if (status === 'pending') {
        categorized.pending.push(request);
      } else if (status === 'rejected') {
        categorized.rejected.push(request);
      } else if (status === 'attended') {
        categorized.attended.push(request);
      } else {
        // Handle any unexpected statuses if necessary
        console.warn(`Unrecognized status "${status}" for request ID: ${request.id}`);
      }
    });

    // Update tab counts
    setTabCount({
      upcoming: categorized.upcoming.length,
      pending: categorized.pending.length,
      rejected: categorized.rejected.length,
      attended: categorized.attended.length,
    });

    setCategorizedData(categorized);
  };

  // Fetch and categorize call requests
  const fetchCallRequests = () => {
    if (!userEmail) {
      setError('User email is not available.');
      return;
    }

    setLoading(true);
    setError(null);

    axios
      .post('/api/get/call_request', {
        email: userEmail,
      })
      .then((response) => {
        console.log('API Response:', response);

        if (response.status === 200 && Array.isArray(response.data)) {
          const callRequests = response.data;
          console.log('All Call Requests from API:', callRequests);

          categorizeAppointments(callRequests);
        } else {
          setError('Invalid data format received from the server.');
        }
      })
      .catch((err) => {
        console.error('Error fetching call_requests:', err);
        setError(
          err.response?.data?.message ||
            'Unable to fetch appointments due to a server error.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Categorize appointments on component mount
  useEffect(() => {
    const initialValue = getActionFromUrl();
    setValue(initialValue);
    fetchCallRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole, currentUserFullName, userEmail]);

  // Callback to re-fetch and categorize appointments
  const refreshAppointments = () => {
    fetchCallRequests();
  };

  // Listen for changes in localStorage (e.g., if data is updated elsewhere)
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'ac_login_user') {
        console.log('Storage changed, re-fetching appointments.');
        fetchCallRequests();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole, currentUserFullName, userEmail]);

  const handleChange = (event, newValue) => {
    console.log('Tab changed to:', newValue);
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            className={classes.transparent}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable prevent tabs example"
            >
              <Tab
                className={`${classes.m} ${classes.global} ${classes.width}`}
                label={
                  <Box fontWeight="600">
                    <FaRegCalendarCheck
                      className={`${classes.tab} ${classes.upcoming}`}
                      fontSize="18px"
                    />
                    Upcoming Appointments ({tabCount.upcoming})
                  </Box>
                }
                {...a11yProps(0)}
              />

              <Tab
                className={`${classes.width} ${classes.global}`}
                label={
                  <Box fontWeight="600">
                    <FaRegCalendarMinus
                      className={`${classes.tab} ${classes.pending}`}
                      fontSize="medium"
                    />
                    Pending Requests ({tabCount.pending})
                  </Box>
                }
                {...a11yProps(1)}
              />

              <Tab
                className={`${classes.width} ${classes.global}`}
                label={
                  <Box fontWeight="600">
                    <FaRegCalendarTimes
                      className={`${classes.tab} ${classes.rejected}`}
                      fontSize="medium"
                    />
                    Rejected Requests ({tabCount.rejected})
                  </Box>
                }
                {...a11yProps(2)}
              />

              <Tab
                className={`${classes.width} ${classes.global}`}
                label={
                  <Box fontWeight="600">
                    <FaRegCalendarAlt
                      className={`${classes.tab} ${classes.attended}`}
                      fontSize="medium"
                    />
                    Attended Appointments ({tabCount.attended})
                  </Box>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>
        </Grid>

        <Grid item xs={12} sm={12}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : error ? (
            <Typography color="error" align="center">
              {error}
            </Typography>
          ) : (
            <>
              <TabPanel value={value} index={0}>
                <Suspense fallback={<div>Loading Upcoming Appointments...</div>}>
                  <UpcomingRequest
                    appointments={categorizedData.upcoming}
                    parentCallback={refreshAppointments} // Passed callback
                  />
                </Suspense>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Suspense fallback={<div>Loading Pending Requests...</div>}>
                  <PendingRequest
                    appointments={categorizedData.pending}
                    parentCallback={refreshAppointments} // Passed callback
                  />
                </Suspense>
              </TabPanel>

              <TabPanel value={value} index={2}>
                <Suspense fallback={<div>Loading Rejected Requests...</div>}>
                  <RejectedRequest
                    appointments={categorizedData.rejected}
                    parentCallback={refreshAppointments} // Passed callback
                  />
                </Suspense>
              </TabPanel>

              <TabPanel value={value} index={3}>
                {/* Attended Appointments - No functionality for now */}
                <Box>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="center"
                  >
                    The "Attended Appointments" feature is coming soon.
                  </Typography>
                </Box>
              </TabPanel>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

// src/components/opengrowth/Courses/CourseDescription.jsx

import React, { useEffect, useState, Suspense, lazy } from 'react';
import {
  Box,
  Typography,
  Chip,
  Card,
  Button,
  CircularProgress,
  Grid,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom'; // Import withRouter
import {
  CalendarToday as CalendarTodayIcon,
  RateReview as RateReviewIcon,
  Star as StarIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import courses from './CourseData'; // Adjust the path as necessary
import useGlobalStyles from '../styles/CourseStyles';
import CourseRegister from './CourseRegister';

// Custom styles for the CourseDescription component
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  backButton: {
    marginBottom: theme.spacing(2),
  },
  card: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    padding: theme.spacing(2, 3),
    border: 'none',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
  },
  leftSection: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  categoryChip: {
    marginBottom: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
  avatars: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: 40,
    height: 40,
    marginLeft: -8,
    border: `2px solid ${theme.palette.background.paper}`,
  },
  authorInfo: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  reviews: {
    display: 'flex',
    alignItems: 'center',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  error: {
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  learnCard: {
    padding: "8px 24px",
    border: 'none',
  },
  actionButtons: {
    borderRadius: '4px',
    marginBottom: '0.35em',
  },
  priceBox: {
    border: '1px solid #e0e0e0',
    padding: '24px',
    borderRadius: '8px',
  },
  h6font: {
    fontWeight: 600,
  },
  learnList: {
    listStyleType: 'disc',
    paddingLeft: theme.spacing(2),
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const CourseDescription = ({ match, history }) => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const { id } = match.params;
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for Enroll Popup
  const [enrollOpen, setEnrollOpen] = useState(false);

  // State for Add to Cart Popup
  const [addToCartOpen, setAddToCartOpen] = useState(false);

  useEffect(() => {
    // Simulate data fetching; replace with actual API call if necessary
    const fetchCourse = async () => {
      try {
        // Simulate network delay
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Find the course by id
        const foundCourse = courses.find((c) => c.id === id);

        if (!foundCourse) {
          throw new Error('Course not found');
        }

        setCourse(foundCourse);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [id]);

  if (loading) {
    return (
      <Box className={classes.loading}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.container}>
        <Typography variant="h5" className={classes.error}>
          {error}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.goBack()}
          className={classes.backButton}
        >
          Go Back
        </Button>
      </Box>
    );
  }

  const instructorNames = course.instructors.map((instructor) => instructor.name).join(', ');

  // Split "What You Will Learn" into two columns if even
  const learnItems = course.whatYouWillLearn;
  const isEven = learnItems.length % 2 === 0;
  const midIndex = isEven ? learnItems.length / 2 : Math.ceil(learnItems.length / 2);
  const firstHalf = learnItems.slice(0, midIndex);
  const secondHalf = learnItems.slice(midIndex);

  // Handlers for Enroll Popup
  const handleEnrollOpen = () => {
    setEnrollOpen(true);
  };

  const handleEnrollClose = () => {
    setEnrollOpen(false);
  };

  // Handlers for Add to Cart Popup
  const handleAddToCartOpen = () => {
    setAddToCartOpen(true);
  };

  const handleAddToCartClose = () => {
    setAddToCartOpen(false);
  };

  return (
    <Box className={classes.container}>
      <Card className={classes.card}>
        <Grid container spacing={3}>
          {/* Left Section - 75% */}
          <Grid item xs={12} md={8} className={classes.leftSection}>
            <Typography variant="h4" className={styleClasses.h4font}>
              {course.title}
            </Typography>
            <Chip label={course.category} className={classes.categoryChip} />

            <Box className={classes.authorInfo}>
              <Typography variant="body1" style={{ marginRight: 'auto' }}>
                {/* {instructorNames} */}
              </Typography>
              <Box className={classes.rating} aria-label={`Rating: ${course.ratings} out of 5`}>
                {Array.from({ length: 5 }, (_, index) => (
                  <StarIcon
                    key={index}
                    color={index < course.ratings ? 'secondary' : 'disabled'}
                    style={{ marginRight: 2 }}
                    aria-hidden="true"
                  />
                ))}
              </Box>
              <Box className={classes.reviews}>
                {/* <RateReviewIcon color="action" style={{ marginRight: 4 }} />
                <Typography variant="body2">{course.reviews} Reviews</Typography> */}
              </Box>
            </Box>
            <Box className={classes.infoBox}>
              <CalendarTodayIcon className={classes.infoIcon} />
              <Typography variant="body2" className={styleClasses.subtitlefont}>
                {course.duration}
              </Typography>
            </Box>
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              {course.description}
            </Typography>
          </Grid>
          {/* Right Section - 25% */}
          <Grid item xs={12} md={4}>
            <Box
              className={classes.imageContainer}
              style={{
                backgroundImage: `url(${
                  course.imageUrl ||
                  'https://academy.opengrowth.com/assets/images/courses/thumb_abc.jpeg'
                })`,
              }}
            />
          </Grid>
        </Grid>
      </Card>

      <Grid container spacing={4} style={{ marginTop: 64 }}>
        {/* Left Part - 75% */}
        <Grid item xs={12} md={8}>
          <Card className={classes.learnCard}>
            <Typography variant="h5" className={styleClasses.h5heading}>
              What You Will Learn
            </Typography>
            <Grid container spacing={2}>
              {isEven ? (
                <>
                  <Grid item xs={12} md={6}>
                    <ul className={classes.learnList}>
                      {firstHalf.map((item, index) => (
                        <li key={index}>
                          <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
                            {item}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ul className={classes.learnList}>
                      {secondHalf.map((item, index) => (
                        <li key={index + midIndex}>
                          <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
                            {item}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <ul className={classes.learnList}>
                    {learnItems.map((item, index) => (
                      <li key={index}>
                        <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
        {/* Right Part - 25% */}
        <Grid item xs={12} md={4}>
          <Box className={classes.priceBox}>
            <Typography variant="h6" className={styleClasses.description} gutterBottom>
              Price: <span className={classes.h6font}>{course.price}</span>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={`${styleClasses.businessButton} ${classes.actionButtons}`}
              fullWidth
              component={Link}
              to="/register/user"
            >
              Enroll Now
            </Button>
            {/* <Button
              variant="outlined"
              color="primary"
              className={`${styleClasses.individualButton} ${classes.actionButtons}`}
              fullWidth
              onClick={handleAddToCartOpen} // Open Add to Cart Popup
            >
              Add to Cart
            </Button> */}
            <Divider style={{ margin: '16px 0' }} />
            <Typography variant="h6" className={styleClasses.h6font}>
              This Course Includes
            </Typography>
            <ul className={classes.learnList}>
              {course.includes.map((item, index) => (
                <li key={index}>
                  <Typography variant="body1" className={styleClasses.body1font}>
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Grid>
      </Grid>

      {/* Enroll Now Dialog */}
      <Dialog
        open={enrollOpen}
        onClose={handleEnrollClose}
        aria-labelledby="enroll-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h6">Enroll in {course.title}</Typography>
          <IconButton aria-label="close" onClick={handleEnrollClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <CourseRegister course={course} onClose={handleEnrollClose} />
        </DialogContent>
      </Dialog>

      {/* Add to Cart Dialog */}
      <Dialog
        open={addToCartOpen}
        onClose={handleAddToCartClose}
        aria-labelledby="add-to-cart-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h6">Add {course.title} to Cart</Typography>
          <IconButton aria-label="close" onClick={handleAddToCartClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <CourseRegister course={course} onClose={handleAddToCartClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

// Define PropTypes for type checking
CourseDescription.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired, // from withRouter
};

export default withRouter(CourseDescription);

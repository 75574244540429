

import React, { useState, useRef, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import {
    Box,
    Typography,
    Button,
    Chip,
    Grid,
    Card,
    CardContent,
    Avatar,
    Divider,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import businesscourse from '../assets/businesscourse.png';
import CommentIcon from '@material-ui/icons/Comment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PromotionalCard from './CourseFeature';
import FAQSection from '../Experts/FaqSection';
import Testimonials from '../Homepage/Testimonials';
import useGlobalStyles from '../styles/CourseStyles';
import BrowseAllCourses from './AllCourseCard';
import courses from './CourseData';
// import Header from '../signup-login/Header';
// import Footer from '../signup-login/Footer';



const useStyles = makeStyles((theme) => ({
    // Additional styles for responsiveness

    featureCard: {
        padding: '0px !important',
        borderRadius: '8px',
        backgroundColor: '#ffffff', // Ensures a white background for better readability
        transition: 'transform 0.3s, box-shadow 0.3s',
        
        marginBottom: '0px', // Space between cards
    },
    subtitlefont: {
        paddingTop: '0rem',
    },
    featureCardContent: {
        padding: '0px'
    },
    featuresSection: {
        padding: 32,
        marginTop: 32,
        marginBottom: 32,
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    },
    // Styles for the new feature card
    newFeatureCard: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%', // Fixed height for the card
        background: 'linear-gradient(to top, #f9bb02 65%, #ffffff 35%)', // Yellow from bottom
        borderRadius: 8,
        overflow: 'hidden',
        border: 'none',
        marginTop: 32, // Space above the card
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'auto',
            justifyContent: 'center',
        },
    },
    newFeatureSection: {
        flex: 1, // Equal width for all sections
        display: 'flex',
        flexDirection: 'column', // Stack vertically
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000', // Black text
        fontWeight: 'bold',
        fontSize: '4.5rem', // Adjusted font size for main text
        padding: '16px',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: '1.5rem',
        },
    },
 
    h5font: {
        backgroundColor: '#fff !important',
        padding: '2px 0px',
    }
}));
const Banner = ({ onExploreNow }) => {
    const theme = useTheme();
    const classes = useGlobalStyles();
    const styleClasses = useStyles();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={classes.bannerContainer}>
            <Box className={`${classes.bannerTextContainer} ${styleClasses.bannerTextContainer}`}>
                <Typography variant="h3" className={classes.h3font}>
                    Cultivate Professional Growth with Our
                    Expert-led <span className={classes.span}>Online Courses</span> for Businesses
                </Typography>
                <Typography variant="h6" className={`${classes.h6banner} ${classes.h6HeadingDesc}`}>
                    On-demand courses for AI startup/expansion
                    stage companies that want to overcome growth
                    challenges and stand out in the market.
                </Typography>
                <Box className={`${classes.filterChipsContainer}`}>
                    <Button
                        variant="contained"
                        className={classes.businessButton}
                        style={{margin: 8}}
                        onClick={onExploreNow}
                        aria-label="Explore all courses"
                    >
                        Explore Now
                    </Button>
                    <Button
                        variant="contained"
                        component={Link}
                        style={{margin: 8}}
                        to="/growth-academy"
                        className={classes.individualButton}
                    >
                        All Courses
                    </Button>
                </Box>
            </Box>
            <Box className={classes.bannerImageContainer}>
                <img
                    src={businesscourse}
                    alt="Banner Image"
                    className={classes.bannerImage}
                />
            </Box>
        </Box>
    );
};

const CourseFeatures = () => {
    useEffect(() => {
        const mainTitle = 'Online Courses for Business | OpenGrowth.AI ';
        const subtitle = 'OpenGrowth.AI offers premium online courses for business growth. Equip yourself with the strategies and tools needed to scale your startup effectively.';
        document.title = `${mainTitle} - ${subtitle}`;
      }, []);
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
    const features = [
        {
            title: 'Access to learning community:',
            description:
                'Communicate with your own team members and others taking the course in the future for added insights and continuous learning.',
        },
        {
            title: 'World-class scaffolding:',
            description:
                'An abundance of resources in the form of blog articles, videos and study materials for those interested in going the extra mile.',
        },
        {
            title: 'Live sessions by Experts:',
            description:
                'Gain insights directly from industry experts, for added guidance and help.',
        },
        {
            title: 'Life-long learning:',
            description:
                'Course delivery promotes and activates life-long learning habits.',
        },
    ];

    return (
        <Box className={classes.featuresSection}>
            <Typography variant="subtitle1" className={styleClasses.subtitlefont} gutterBottom>
                Save on talent development costs with tailored content and practical insights from over 50 leading experts
            </Typography>
            <Typography
                variant="h4"
                className={styleClasses.h4font}
            >
                Features
            </Typography>
            <Grid container direction="column">
                {features.map((feature, index) => (
                    <Grid item xs={12} key={index}>
                        <Card className={`${styleClasses.featureCard} ${classes.featureCard}`}>
                            <CardContent className={classes.featureCardContent}>
                                <Typography
                                    variant="h5"
                                    className={`${styleClasses.h5font} ${classes.h5font}`}
                                >
                                    {feature.title}
                                </Typography>
                                <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.subtitlefont}`}>
                                    {feature.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* New Feature Card */}
            <Card className={classes.newFeatureCard}>
                <Box className={classes.newFeatureSection}>
                    <Typography variant="h1" className={styleClasses.h1font}>
                        5X
                    </Typography>
                    <Typography variant="subtitle2" className={styleClasses.subtitlefont}>
                        Faster learning with online methods
                    </Typography>
                </Box>
                <Box className={classes.newFeatureSection}>
                    <Typography variant="h1" className={styleClasses.h1font}>
                        40%
                    </Typography>
                    <Typography variant="subtitle2" className={styleClasses.subtitlefont}>
                        Less time required for training
                    </Typography>
                </Box>
                <Box className={classes.newFeatureSection}>
                    <Typography variant="h1" className={styleClasses.h1font}>
                        60%
                    </Typography>
                    <Typography variant="subtitle2" className={styleClasses.subtitlefont}>
                        Improved retention with digital learning
                    </Typography>
                </Box>
            </Card>
        </Box>
    );
};

// Main AllCourses Component
const BusinessCourse = () => {
    const browseAllCoursesRef = useRef(null);
    const classes = useStyles();
    const styleClasses = useGlobalStyles();

    const handleExploreNow = () => {
        if (browseAllCoursesRef.current) {
            browseAllCoursesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box style={{ padding: 8, paddingTop: 0 }}>
            {/* <Header /> */}
            <Banner onExploreNow={handleExploreNow} />
            <CourseFeatures />

            {/* Browse All Courses Section */}
            <Box ref={browseAllCoursesRef}>
                <BrowseAllCourses courses={courses} context={'business'} />
            </Box>
            {/* <Testimonials context='businesscourses' /> */}
            <FAQSection context='businessCourse' />
            {/* <Footer /> */}
        </Box>
    );
};

export default BusinessCourse;

// src/components/GrowthBenefitsCard.jsx

import React from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useGlobalStyles from '../styles/CourseStyles';

// Import your images here. Ensure you have these images in the specified path.
// Replace these imports with your actual image paths or URLs.
import benefit1 from '../assets/benefit1.png';
import benefit2 from '../assets/benefit2.png';
import benefit3 from '../assets/benefit3.svg';
import benefit4 from '../assets/benefit4.png';

// Studio-specific images (ensure these are added to your assets)
import studioBenefit1 from '../assets/studioBenefit1.png';
import studioBenefit2 from '../assets/growthBenefit2.png';
import studioBenefit3 from '../assets/growthBenefit3.png';
import studioBenefit4 from '../assets/growthBenefit4.png';

const useStyles = makeStyles((theme) => ({
  mainCard: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(8),
    padding: theme.spacing(4),
    border: 'none',
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff', // Optional: Set a background color
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
  },
  titleBox: {
    textAlign: 'left', // Align title to the left
    marginBottom: theme.spacing(4),
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: '1.75rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      padding: '0px',
    },
  },
  benefitsGrid: {
    marginTop: theme.spacing(2),
  },
  benefitCard: {
    borderRadius: theme.shape.borderRadius * 1,
    padding: theme.spacing(1),
    // border: '7px solid #25387c',
    border: 'none',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    backgroundColor: '#f9f9f9', // Light background for contrast
    height: '100%', // Ensure all cards have equal height
    width: '100%',
    minHeight: '250px', // Added minHeight for better vertical centering
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center', // Center content vertically
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
    },
  },
  benefitImage: {
    width: 'auto', // Fixed width
    height: '120px', // Fixed height
    objectFit: 'cover',
    marginBottom: theme.spacing(2),
  },
  benefitDescription: {
    textAlign: 'center',
    // Removed marginTop: 'auto' to prevent pushing description to bottom
  },
  h6: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '250px',
    height: '80px',
    fontWeight: '500',
    backgroundColor: '#f9bb02',
    borderRadius: '12px',
    padding: '8px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '100%',
      height: '100%',
    },
  },
  divider: {
    backgroundColor: '#f9bb02', // Set to yellow
    height: '7px',               // Thicker divider
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subtitlefont: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  box: {
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
  },
  body1: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  }
}));

const GrowthBenefitsCard = ({ context = 'allExpert' }) => { // Accept context prop with default value
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();

  // Apply column layout for screens smaller than the 'md' breakpoint
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Define the benefits data for 'allExpert' context
  const allExpertBenefits = [
    {
      id: 1,
      image: benefit1,
      title: 'Expertise without Full-Time Commitment',
    },
    {
      id: 2,
      image: benefit2,
      title: 'Cost-Effective Solutions',
      
    },
    {
      id: 3,
      image: benefit3,
      title: 'Strategic Impact',
      
    },
    {
      id: 4,
      image: benefit4,
      title: 'Flexibility to Scale',
      
    },
  ];

  // Define the benefits data for 'studio' context
  const studioBenefits = [
    {
      id: 1,
      image: studioBenefit1,
      title: 'Sharing Your Knowledge',
      description:
        "We'll curate top growth content and collaborate on engaging, bite-sized micro-learning topics.",
    },
    {
      id: 2,
      image: studioBenefit2,
      title: "Showcasing Your Expertise",
      description:
        'Growth Studio is a platform for growth experts to share insights and showcase expertise.',
    },
    {
      id: 3,
      image: studioBenefit3,
      title: 'Becoming a Trusted Advisor',
      description:
        'Podcast interviews and content help you become a recognized "Growth Expert" and build your reputation.',
    },
    {
      id: 4,
      image: studioBenefit4,
      title: 'Empower Others',
      description:
        'Share your experiences to guide aspiring entrepreneurs through potential challenges and pitfalls.',
    },
  ];

  // Choose the appropriate data set based on the context
  const benefits = context === 'studio' ? studioBenefits : allExpertBenefits;

  // Define the heading and subheading based on the context
  const heading = context === 'studio' ? 'What is Growth Studio?' : 'Benefits of Hiring Fractional Experts';
  const subheading = context === 'studio' 
    ? 'Growth Studio allows startup founders and growth leaders to share or showcase their experiences, expertise, and learnings, providing value to other entrepreneurs who want to establish themselves as trusted advisors and Growth Experts. Publish your story in our newsletters, website, social media channels and help other startup founders to learn from your experience.'
    : '';

    const subheading2 = context === 'studio' 
    ? 'Growth Studio can help you get recognized as a growth expert by:'
    : '';

  return (
    <Card className={classes.mainCard}>
      {/* Card Title */}
      <Box className={classes.titleBox}>
        <Typography variant="h4" className={styleClasses.h4font} gutterBottom>
          {heading}
        </Typography>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.subtitlefont}`}>
          {subheading}
        </Typography>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.subtitlefont}`}>
          {subheading2}
        </Typography>
      </Box>
      
      {/* Customized Divider */}
      <Divider className={classes.divider} />
      
      {/* Benefits Grid */}
      <Grid
        container
        spacing={4}
        className={classes.benefitsGrid}
        justifyContent="center"
      >
        {benefits.map((benefit) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={benefit.id}
            style={{ display: 'flex' }}
          >
            <Card className={classes.benefitCard}>
              <img
                src={benefit.image}
                alt={benefit.title}
                className={classes.benefitImage}
                loading="lazy" // Lazy loading for performance
              />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
                className={classes.box}
              >
                <Typography
                  variant="h6" // Changed back to h6 for consistency
                  className={`${styleClasses.h6font} ${classes.h6}`}
                  gutterBottom
                >
                  {benefit.title}
                </Typography>
                {benefit.description && 
                <Typography variant="body1" className={`${styleClasses.body1font} ${classes.body1}`}>
                  {benefit.description}
                </Typography>
            }
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default GrowthBenefitsCard;

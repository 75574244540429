// PricingCards Component
import React, {useState} from 'react';
import {
  Box,
  Typography,
  Card,
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core';
import useGlobalStyles from '../styles/CourseStyles'; // Ensure this path is correct
import HandIcon from '../assets/Venture/pricing_icon1.png'; // Replace with the correct path to your image
import ClockIcon from '../assets/Venture/pricing_icon2.png'; // Replace with the correct path to your image
import CalendarIcon from '../assets/Venture/pricing_icon3.png';
import TickIcon from '../assets/Venture/tick_icon.png';
import PricingTable from './PricingTable';
import { PopupButton } from 'react-calendly';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
  },
  titleBox: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3),
    },
  },
  gridItem: {
    display: 'flex', // Make Grid items flex containers
  },
  card: {
    flex: 1, // Allow Card to grow and fill the Grid item
    display: 'flex', // Enable Flexbox in Card
    flexDirection: 'column', // Stack children vertically
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    padding: theme.spacing(3, 1),
    textAlign: 'left',
    border: 'none',
    backgroundColor: '#ffffff', // Ensure card has a white background
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  h6: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    fontWeight: '500',
    backgroundColor: '#f9bb02',
    marginBottom: theme.spacing(1),
    borderRadius: '12px',
    padding: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
    },
  },
  subtitle2: {
    height: '35px',
    color: '#25387c',
    marginBottom: theme.spacing(1),
  },
  price: {
    fontWeight: '500',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  includesTitle: {
    color: '#25387c',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  processTitle: {
    color: '#25387c',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  benefitsTitle: {
    color: '#25387c',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  button: {
    
    marginTop: 'auto', 
    '&:hover': {
      backgroundColor: '#e6b800',
    },
  },

  processSection: {
    backgroundColor: '#FFF5CC',
    padding: theme.spacing(4, 6),
    borderRadius: '8px',
    margin: theme.spacing(2, 4),
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 3),
      margin: theme.spacing(2, 2),
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
  },
  iconImage: {
    width: '105px',
    height: '100px',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.5),
    backgroundColor: '#f9bb02',
    borderRadius: '8px'
  },
  processTitle: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  priceText: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#25387c',
  },
  getStartedButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#FFC107',
    color: '#25387c',
    '&:hover': {
      backgroundColor: '#e6b800',
    },
  },

  priceContainer: {
    display: 'flex',
    padding: theme.spacing(4,4,0,4),
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      padding: theme.spacing(2),
      gap: '16px',
    },
  },
  card: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    padding: theme.spacing(3, 1),
    textAlign: 'center',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down("sm")]: {
      width: '95%',
    },
  },
  gradientCard: {
    background: 'linear-gradient(135deg, #f0e7f0 0%, #7f5bff 100%)',
    position: 'relative',
  },
  popularTag: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: '#f9bb02',
    color: '#25387c',
    fontWeight: 'bold',
    padding: '5px 10px',
    borderRadius: '20px',
  },
  price: {
    fontWeight: '500',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    color: '#000'
  },
  pricebutton: {
    marginTop: 'auto',
    backgroundColor: '#FFC107',
    color: '#25387c',
    '&:hover': {
      backgroundColor: '#e6b800',
    },
  },
  listItem: {
    marginBottom: theme.spacing(1),
    display: 'flex', // Align text and image horizontally
    alignItems: 'center',
  },
  tickIcon: {
    width: '20px', // Size of the tick icon
    marginRight: theme.spacing(1), // Space between icon and text
  },
  promoButton : {
    padding: theme.spacing(1, 4),
  }
}));

const PricingCards = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const [calendlyUrl] = useState('https://calendly.com/vedika-5m0y/30min?month=2024-10');

  return (
    <Box className={`${classes.cardContainer}`}>
        <Box className={classes.titleBox}>
        <Typography variant="h4" className={styleClasses.h4font} gutterBottom>
          Pricing Plans
        </Typography>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.subtitlefont}`} gutterBottom>
        At OpenGrowth.AI, we offer two plans: One for startups seeking tailored growth guidance and a customizable Venture
        Assistance plan featuring experts, associates, and specialized services to drive business success. Check details below 
        </Typography>
      </Box>

      {/* Process Section */}
      <Box className={classes.processSection}>
        <Typography variant="h4" className={styleClasses.h4font}>
          Expert on Demand
        </Typography>
        <Typography variant="h6" className={styleClasses.description} gutterBottom>
          Perfect for short-term needs or one-off consultations
        </Typography>
        
        <Grid container spacing={7} alignItems="flex-start">
          {/* Grid 1: Icons and Descriptions */}
          <Grid item xs={12} md={8}>
          <Typography variant="h5" className={styleClasses.h5fontPara} style={{textAlign: 'left', color: '#25387c'}} gutterBottom>
          Process :
        </Typography>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Box className={classes.iconContainer}>
                  <img src={HandIcon} alt="Choose Expert" className={classes.iconImage} />
                  <Typography variant="subtitle2" className={styleClasses.subtitle2font}>Choose your Growth Expert</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box className={classes.iconContainer}>
                  <img src={ClockIcon} alt="Select Hours" className={classes.iconImage} />
                  <Typography variant="subtitle2" className={styleClasses.subtitle2font}>Select the number of hours</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box className={classes.iconContainer}>
                  <img src={CalendarIcon} alt="Schedule Appointment" className={classes.iconImage} />
                  <Typography variant="subtitle2" className={styleClasses.subtitle2font}>Schedule your appointment with our team</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Grid 2: Price and Button */}
          <Grid item xs={12} md={4} style={{paddingTop: 0}}>
            <Typography variant="h5" className={styleClasses.h5fontPara} style={{textAlign: 'left'}}>
              *Starting from : 
            </Typography>
            <Typography variant="h1" className={styleClasses.h1font} style={{color: '#25387c', marginBottom: '8px'}}>
              $200 / hour
            </Typography>
            <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Get Started"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
          </Grid>
        </Grid>
        </Box>

        <Box className={classes.priceContainer}>
      {/* Starter Plan */}
      <Card className={classes.card}>
        <Typography variant="h4" className={styleClasses.h4font}>
          Starter
        </Typography>
        <Typography variant="body1" className={styleClasses.body1font}>
          Ideal for businesses requiring consistent guidance with flexibility.
        </Typography>
        <Typography variant="h4" className={`${styleClasses.h4font} ${classes.price}`}>
          $4000/month*
        </Typography>
        <ul style={{textAlign:'left'}}>
          <li className={classes.listItem}>
            <img src={TickIcon} alt="tick" className={classes.tickIcon} />
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              6 hours per week of Growth Expert services
            </Typography>
          </li>
          <li className={classes.listItem}>
            <img src={TickIcon} alt="tick" className={classes.tickIcon} />
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              Scheduled weekly sessions
            </Typography>
          </li>
          <li className={classes.listItem}>
            <img src={TickIcon} alt="tick" className={classes.tickIcon} />
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              Dedicated chat support
            </Typography>
          </li>
        </ul>
        <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Get Started"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
      </Card>

      {/* Growth Plan (Most Popular) */}
      <Card className={`${classes.card} ${classes.gradientCard}`}>
        <Typography variant="h4" className={styleClasses.h4font}>
          Growth
        </Typography>
        <Typography variant="body1" className={styleClasses.body1font}>
          Ideal for businesses needing expert guidance, priority access, and seamless support.
        </Typography>
        <Typography variant="h4" className={`${styleClasses.h4font} ${classes.price}`}>
          $7000/month*
        </Typography>
        <ul style={{textAlign:'left'}}>
          <li className={classes.listItem}>
            <img src={TickIcon} alt="tick" className={classes.tickIcon} />
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              12 hours per week of Growth Expert services
            </Typography>
          </li>
          <li className={classes.listItem}>
            <img src={TickIcon} alt="tick" className={classes.tickIcon} />
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              Dedicated Growth Associate for additional support
            </Typography>
          </li>
          <li className={classes.listItem}>
            <img src={TickIcon} alt="tick" className={classes.tickIcon} />
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              Priority scheduling
            </Typography>
          </li>
        </ul>
        <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Get Started"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
        <div className={classes.popularTag}>Most Popular</div>
      </Card>

      {/* Scale Plan */}
      <Card className={classes.card}>
        <Typography variant="h4" className={styleClasses.h4font}>
          Scale
        </Typography>
        <Typography variant="body1" className={styleClasses.body1font}>
          Tailored to your specific business needs.
        </Typography>
        <Typography variant="h4" className={`${styleClasses.h4font} ${classes.price}`}>
          Build-Your-Own Plan
        </Typography>
        <ul style={{textAlign:'left'}}>
          <li className={classes.listItem}>
            <img src={TickIcon} alt="tick" className={classes.tickIcon} />
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              Fully adaptable to your unique challenges
            </Typography>
          </li>
          <li className={classes.listItem}>
            <img src={TickIcon} alt="tick" className={classes.tickIcon} />
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              Access to multi-disciplinary Growth Experts
            </Typography>
          </li>
          <li className={classes.listItem}>
            <img src={TickIcon} alt="tick" className={classes.tickIcon} />
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              Scalable and flexible options
            </Typography>
          </li>
        </ul>
        <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Get Started"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
      </Card>
    </Box>
    <Box style={{padding: '0px 24px'}}>
      <PricingTable />
    </Box>
    
      
    </Box>
  );
};

export default PricingCards;

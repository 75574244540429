// src/components/Banner.jsx

import React from 'react';
import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PopupButton } from 'react-calendly';
import Slider from 'react-slick';

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import global styles if any
import useGlobalStyles from '../styles/CourseStyles';

// Import banner images
import expert_banner1 from '../assets/expert_banner1.png';
import expert_banner2 from '../assets/expert_banner2.png';
// Add more images as needed

// Import Link if needed (not used anymore for the button)
import { Link } from 'react-router-dom';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  bannerWrapper: {
    background: 'linear-gradient(to right, #041a57 , #041a57)', // Background gradient
    width: '100%',
    padding: theme.spacing(0, 0),
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(1, 2, 0, 2),
    },
  },
  slider: {
    width: '100%', // Ensure slider takes full width
    '& .slick-slide': {
      display: 'flex !important',
      justifyContent: 'center',
      flexWrap: 'nowrap',
    },
    '& .slick-dots': {
      bottom: theme.spacing(2),
    },
    '& .slick-arrow': {
      zIndex: 1, // Ensure arrows are above other elements
    },
    // Customize dots and arrows if needed
    '& .slick-dots li button:before': {
      color: '#f9bb02', // Dot color
    },
    '& .slick-prev:before, .slick-next:before': {
      color: '#f9bb02', // Arrow color
    },
  },
  slide: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(5, 4, 0, 4),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing(1, 0),
    },
  },
  content: {
    width: '75%',
    flex: 2,
    color: '#fff',
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(7),
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      textAlign: 'left',
      width: '100%',
    },
  },
  title: {
    color: '#f9bb02', // Matches the h3font color
    lineHeight: '75px',
    width: '915px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
      lineHeight: '1.3',
      width: 'auto',
    },
  },
  buttonContainer : {
    display: 'flex',
    gap: 24,
  },
  bannerButton: {
    padding: '8px 12px',
    cursor: "pointer",
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 20px',
    },
  },
  imageContainer: {
    flex: 3.5,
    width: '100%',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(0),
    },
  },
  image: {
    width: '110%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
  },
}));

// Banner Component with Carousel
const Banner = ({ onExploreClick }) => { // Accept the prop here
  const classes = useStyles();
  const styleClasses = useGlobalStyles(); // Ensure this hook exists and is correctly implemented
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // Slider settings
  const settings = {
    infinite: true, // Loop through slides
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll on each action
    autoplay: true, // Enable autoplay
    arrows: false,
    autoplaySpeed: 5000, // Autoplay speed in milliseconds
    adaptiveHeight: true, // Adjust slide height based on content
  };

  // Define slides data with different headings and content
  const slides = [
    {
      title: 'AI Agents + Human Intelligence + Growth Expert Networks = Growth Selling',
      subtitle:
        'In a buyer centric world, AI with Expert Intelligence and Relationships holds the key to sales pipeline creation and revenue growth.',
      calendlyUrl: 'https://calendly.com/opengrowth-support/30min', // Replace with your actual Calendly URL
      imageUrl: expert_banner2,
    },
    // Add more slides with different headings and images as needed
  ];

  return (
    <Box className={classes.bannerWrapper}>
      <Slider {...settings} className={classes.slider}>
        {slides.map((slide, index) => (
          <Box key={index} className={classes.slide}>
            {/* Content Section */}
            <Box className={classes.content}>
              <Typography
                className={`${styleClasses.h1heading} ${classes.title}`}
                variant={isSmall ? 'h5' : 'h1'}
                gutterBottom
              >
                {slide.title}
              </Typography>
              <Typography
                variant={isSmall ? 'subtitle1' : 'h6'}
                className={`${styleClasses.h6banner} ${styleClasses.h6HeadingDesc}`}
                align={isSmall ? 'left' : 'left'}
                gutterBottom
              >
                {slide.subtitle}
              </Typography>
              <Box className={classes.buttonContainer}>
                <PopupButton
                  url={slide.calendlyUrl}
                  rootElement={document.getElementById('root') || undefined}
                  text="Book a Discovery Call"
                  className={`${styleClasses.individualButton} ${classes.bannerButton}`}
                  variant="contained"
                />
                <Button
                  variant="contained"
                  className={`${styleClasses.individualButton} ${classes.bannerButton}`}
                  onClick={onExploreClick} // Call the scroll function on click
                >
                  Explore Growth Experts
                </Button>
              </Box>
            </Box>

            {/* Image Section */}
            <Box className={classes.imageContainer}>
              <img
                src={slide.imageUrl}
                alt={`Expert Banner ${index + 1}`}
                className={classes.image}
                loading="lazy" // Improves performance by lazy loading images
              />
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Banner;
